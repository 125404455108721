import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  CircleLayout,
  CirclePaint,
  GeoJSONSource,
  GeoJSONSourceRaw,
  Layer,
  LineLayout,
  LinePaint,
  SymbolLayout,
  SymbolPaint,
} from 'mapbox-gl';
import { Feature, FeatureCollection } from '@turf/turf';
import { useMap } from 'legoland-shared';

const SourceContext = React.createContext<{
  source: GeoJSONSource;
  id: string;
  addLayer: (layer: mapboxgl.AnyLayer, before?: string) => void;
  removeLayer: (layerId: string) => void;
}>({} as any);

interface Props extends Omit<GeoJSONSourceRaw, 'type' | 'data'> {
  id?: string;
  children: React.ReactNode;
  data:
  | Feature
  | Feature[]
  | FeatureCollection
  | GeoJSON.Feature<GeoJSON.Geometry>
  | GeoJSON.FeatureCollection<GeoJSON.Geometry>
  | GeoJSON.Feature<GeoJSON.Geometry>[]
  | string;
}

const empty: any = {
  type: 'FeatureCollection',
  features: [],
};
const parseData = (data: any): GeoJSON.FeatureCollection => {
  let parsed;

  if (data === undefined) {
    parsed = empty;
  } else if (data instanceof Array) {
    parsed = { type: 'FeatureCollection', features: data.filter(Boolean) };
  } else {
    parsed = data;
  }
  return parsed;
};

// Create random id unless one is provided
const useId = (id?: string, prefix?: string) => {
  const [generatedId] = useState(
    () => `${prefix ?? ''}-${Math.floor(Math.random() * 999999)}`,
  );

  return id ?? generatedId;
};

export function Source({ id, data, children, ...rest }: Props) {
  const { map } = useMap();
  const sourceId = useId(id);

  const onSourceLoad = useRef([]);
  const [addedLayers] = useState(() => new Set<string>());

  useEffect(() => {
    map.addSource(sourceId, {
      type: 'geojson',
      data: parseData(data),
      ...rest,
    });
    onSourceLoad.current.forEach((fn) => fn());
    onSourceLoad.current = [];

    return () => {
      addedLayers.forEach((layerId) => {
        if (map.getLayer(layerId)) {
          map.removeLayer(layerId);
        }
      });
      addedLayers.clear();

      if (map.getSource(sourceId)) {
        map.removeSource(sourceId);
      }
    };
  }, []);

  const addLayer = useCallback((layer: mapboxgl.AnyLayer, before?: string) => {
    // Source already loaded, add immediately
    if (map.getSource(sourceId)) {
      map.addLayer(layer, before);
    } else {
      // Source not loaded yet, add to queue
      onSourceLoad.current.push(() => map.addLayer(layer, before));
    }
    addedLayers.add(layer.id);
  }, []);

  const removeLayer = useCallback((layerId: string) => {
    if (addedLayers.has(layerId)) {
      addedLayers.delete(layerId);
      map.removeLayer(layerId);
    }
  }, []);

  useEffect(() => {
    (map.getSource(sourceId) as GeoJSONSource | undefined)?.setData(parseData(data));
  }, [data]);

  const contextValue = useMemo(
    () => ({
      source: map.getSource(sourceId) as GeoJSONSource,
      id: sourceId,
      addLayer,
      removeLayer,
    }),
    [],
  );

  return (
    <SourceContext.Provider value={contextValue}>
      {children}
    </SourceContext.Provider>
  );
}

const layerProperties = [
  'metadata',
  'ref',
  'minzoom',
  'maxzoom',
  'interactive',
  'filter',
];

function makeLayerComponent<Paint, Layout>(
  type: string,
  layoutProperties: string[],
  paintProperties: string[],
) {
  type Props = Paint &
    Layout &
    Omit<Layer, 'type' | 'paint' | 'layout' | 'id'> & {
      id?: string;
      beforeId?: string;
    };

  function binProperties(
    rest: Omit<Props, 'id' | 'beforeId'>,
  ): {
    paint: Paint;
    layout: Layout;
    layer: any;
  } {
    const layout = {} as Layout;
    const paint = {} as Paint;
    const layer = {};

    Object.entries(rest).forEach(([key, value]) => {
      if (layoutProperties.includes(key)) {
        layout[key] = value;
      }
      if (paintProperties.includes(key)) {
        paint[key] = value;
      }
      if (layerProperties.includes(key)) {
        layer[key] = value;
      }
    });

    return {
      layout,
      paint,
      layer,
    };
  }

  const Component = function ({ id, beforeId, ...rest }: Props) {
    const { map } = useMap();
    const { id: sourceId, addLayer, removeLayer } = useContext(SourceContext);
    const layerId = useId(id, type);

    useEffect(() => {
      const { layout, paint, layer } = binProperties(rest);

      addLayer(
        {
          id: layerId,
          layout,
          paint,
          type,
          ...layer,
          source: sourceId,
        },
        beforeId,
      );

      return () => {
        removeLayer(layerId);
      };
    }, [beforeId]);

    useEffect(() => {
      if (!map.getLayer(layerId)) return;

      const { layout, paint } = binProperties(rest);

      Object.entries(layout).forEach(([key, value]) => {
        if (map.getLayoutProperty(layerId, key) !== value) {
          map.setLayoutProperty(layerId, key, value);
        }
      });
      Object.entries(paint).forEach(([key, value]) => {
        if (map.getPaintProperty(layerId, key) !== value) {
          map.setPaintProperty(layerId, key, value);
        }
      });
    }, [JSON.stringify(rest)]);

    (Component as any).displayName = `${type}Layer - ${layerId}`;

    return null;
  };

  return Component;
}

export const Lines = makeLayerComponent<LinePaint, LineLayout>(
  'line',
  [
    'line-cap',
    'line-join',
    'line-miter-limit',
    'line-round-limit',
    'line-sort-key',
  ],
  [
    'line-opacity',
    'line-opacity-transition',
    'line-color',
    'line-color-transition',
    'line-translate',
    'line-translate-transition',
    'line-translate-anchor',
    'line-width',
    'line-width-transition',
    'line-gap-width',
    'line-gap-width-transition',
    'line-offset',
    'line-offset-transition',
    'line-blur',
    'line-blur-transition',
    'line-dasharray',
    'line-dasharray-transition',
    'line-pattern',
    'line-pattern-transition',
    'line-gradient',
  ],
);

export const Circles = makeLayerComponent<CirclePaint, CircleLayout>(
  'circle',
  ['circle-sort-key', 'visibility'],
  [
    'circle-radius',
    'circle-radius-transition',
    'circle-color',
    'circle-color-transition',
    'circle-blur',
    'circle-blur-transition',
    'circle-opacity',
    'circle-opacity-transition',
    'circle-translate',
    'circle-translate-transition',
    'circle-translate-anchor',
    'circle-pitch-scale',
    'circle-pitch-alignment',
    'circle-stroke-width',
    'circle-stroke-width-transition',
    'circle-stroke-color',
    'circle-stroke-color-transition',
    'circle-stroke-opacity',
    'circle-stroke-opacity-transition',
  ],
);

export const Symbols = makeLayerComponent<SymbolPaint, SymbolLayout>(
  'symbol',
  [
    'symbol-placement',
    'symbol-spacing',
    'symbol-avoid-edges',
    'symbol-z-order',
    'icon-allow-overlap',
    'icon-ignore-placement',
    'icon-optional',
    'icon-rotation-alignment',
    'icon-size',
    'icon-text-fit',
    'icon-text-fit-padding',
    'icon-image',
    'icon-rotate',
    'icon-padding',
    'icon-keep-upright',
    'icon-offset',
    'icon-anchor',
    'icon-pitch-alignment',
    'text-pitch-alignment',
    'text-rotation-alignment',
    'text-field',
    'text-font',
    'text-size',
    'text-max-width',
    'text-line-height',
    'text-letter-spacing',
    'text-justify',
    'text-anchor',
    'text-max-angle',
    'text-rotate',
    'text-padding',
    'text-keep-upright',
    'text-transform',
    'text-offset',
    'text-allow-overlap',
    'text-ignore-placement',
    'text-optional',
    'text-radial-offset',
    'text-variable-anchor',
    'text-writing-mode',
    'symbol-sort-key',
  ],
  [
    'icon-opacity',
    'icon-opacity-transition',
    'icon-color',
    'icon-color-transition',
    'icon-halo-color',
    'icon-halo-color-transition',
    'icon-halo-width',
    'icon-halo-width-transition',
    'icon-halo-blur',
    'icon-halo-blur-transition',
    'icon-translate',
    'icon-translate-transition',
    'icon-translate-anchor',
    'text-opacity',
    'text-opacity-transition',
    'text-color',
    'text-color-transition',
    'text-halo-color',
    'text-halo-color-transition',
    'text-halo-width',
    'text-halo-width-transition',
    'text-halo-blur',
    'text-halo-blur-transition',
    'text-translate',
    'text-translate-transition',
    'text-translate-anchor',
  ],
);

const useImageUrl = (name: string, url: string) => {
  const { map } = useMap();

  useEffect(() => {
    const img = new Image();
    img.src = url;

    const loadImage = () => {
      if (map.hasImage(name)) {
        return;
      }
      map.addImage(name, img);
    };

    img.onload = () => {
      loadImage();
      map.on('styledata', loadImage);
    };

    return () => {
      if (map.hasImage(name)) {
        map.removeImage(name);
      }
      map.off('styledata', loadImage);
    };
  }, [name, url]);
};

export function IconImage({ id, src }: { id: string; src: string }) {
  useImageUrl(id, src);
  return null;
}
