import { Range } from 'rc-slider';
import { Dispatch, SetStateAction, useRef } from 'react';
import styled from 'styled-components';
import {
  Box,
  Button,
  FormGroup,
  Input,
  Text,
  Tooltip,
  useTombac,
} from 'tombac';
import { EditIcon } from 'tombac-icons';
import { PaletteScale } from './ColorPalette';
import { NumberInput } from 'components/UI/Input/NumberInput';

const InputDash = styled.div`
  width: 8px;
  height: 1px;
  background: hsl(0, 0%, 90%);
  margin: 0 3px;
`;

interface ScalePickerProps {
  isDiscrete: boolean;
  scale: PaletteScale;
  unitLabel: string;
  onChange: Dispatch<SetStateAction<PaletteScale>>;
}

export const ScalePicker: React.FC<ScalePickerProps> = ({
  isDiscrete,
  scale,
  unitLabel,
  onChange,
}) => {
  const tombac = useTombac();

  const scaleMaxRef = useRef<HTMLInputElement>(null);
  const toggleRef = useRef<any>();

  const updateScaleMax = () => {
    if (!scaleMaxRef.current) return;

    const value = Number(scaleMaxRef.current.value);
    if (isNaN(value)) return;

    onChange({ ...scale, max: value });
    toggleRef.current?.();
  };

  const handleInput = (type: 'from' | 'to') => (value: any) => {
    const newScale = { ...scale, [type]: Number(value) };
    if (newScale.from < newScale.to) {
      onChange(newScale);
    }
  };

  return (
    <Box>
      <Box $display="flex" $alignItems="center">
        <NumberInput
          $height="24px"
          $width="90px"
          value={scale.from}
          onChange={handleInput('from')}
          isInvalid={(n) => n > scale.to}
          min={scale.min}
          max={scale.max}
        />
        <InputDash />
        <NumberInput
          $height="24px"
          $width="90px"
          value={scale.to}
          onChange={handleInput('to')}
          min={scale.min}
          max={scale.max}
          isInvalid={(n) => n < scale.from}
        />
        <Text $ml="1sp">{unitLabel}</Text>
      </Box>
      <Box $display="flex" $ml="1sp" $mt="1sp">
        <Range
          min={scale.min}
          max={scale.max}
          step={isDiscrete ? scale.step : undefined}
          value={[scale.from, scale.to]}
          marks={{
            [scale.min]: scale.min,
            [scale.max]: scale.max,
          }}
          trackStyle={[
            {
              background: '#61ade0',
            },
          ]}
          handleStyle={[
            {
              borderColor: '#61ade0',
            },
            {
              borderColor: '#61ade0',
            },
          ]}
          style={{ fontFamily: tombac.fontFamily }}
          onChange={([from, to]) => onChange({ ...scale, from, to })}
        />
        <Tooltip
          content={
            <FormGroup label="Scale maximum value" $mb="1sp">
              <Input
                $height="32px"
                $width="110px"
                defaultValue={scale.max}
                ref={scaleMaxRef}
              />
              <Button onClick={updateScaleMax} $height="32px" $ml="1sp">
                Change
              </Button>
            </FormGroup>
          }
          placement="top"
        >
          {({ ref, toggle }) => (
            (toggleRef.current = toggle),
            (
              <Button
                $ml="2sp"
                onClick={toggle}
                ref={ref}
                shape="circle"
                size="xs"
              >
                <EditIcon />
              </Button>
            )
          )}
        </Tooltip>
      </Box>
    </Box>
  );
};
