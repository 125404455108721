import React from 'react';
import { FormGroup, Text } from 'tombac';
import { MapMenuToggle } from 'components/UI/MapMenu/MapMenuToggle';

interface Props {
  carsPerKm: boolean;
  setCarsPerKm: (value: React.SetStateAction<boolean>) => void;
  regionNames: boolean;
  setRegionNames: (value: React.SetStateAction<boolean>) => void;
  filter: number;
  setFilter: (value: React.SetStateAction<number>) => void;
}

export const SpatialSankeyMapControls: React.FC<Props> = ({
  carsPerKm,
  setCarsPerKm,
  regionNames,
  setRegionNames,
  filter,
  setFilter,
}) => {
  return (
    <>
      <MapMenuToggle
        label="Flows/km²"
        checked={carsPerKm}
        onChange={() => setCarsPerKm(!carsPerKm)}
      />
      <MapMenuToggle
        label="Region names"
        checked={regionNames}
        onChange={() => setRegionNames(!regionNames)}
      />
      <FormGroup label="Lower limit">
        <Text>{filter}%</Text>
        <input
          type="range"
          min={0}
          max={100}
          value={filter}
          onChange={(e) => setFilter(Number(e.target.value))}
        />
      </FormGroup>
    </>
  );
};
