import { getApiKey } from 'api/api';
import { Scenario } from 'components/AnalysisViewPage/MapFlowsPro/logic/scenario';
import { buildSelectedLinkTripsDebugLink } from 'logic/debug/tripsDebugLink';
import { Analysis } from 'model/AnalysisDto';
import { Button } from 'tombac';
import { LimitsRouteIcon } from 'tombac-icons';

interface Props {
  analysis: Analysis;
  scenario: Scenario;
}

export const ShowDebugTracesButton = ({ analysis, scenario }: Props) => {
  const key = getApiKey();
  const handleButtonClick = () => {
    if (key) {
      const link = buildSelectedLinkTripsDebugLink(
        analysis.info.id,
        scenario.timeRange,
        scenario.dateRange,
        key,
      );
      window.open(link, '_blank');
    } else {
      console.error('API key is missing');
    }
  };

  return (
    <>
      <Button onClick={handleButtonClick} size="xs" variant="primary">
        <LimitsRouteIcon />
        Show debug traces
      </Button>
    </>
  );
};
