import { Box, Button } from 'tombac';
import { ChevronSDownIcon, ChevronSUpIcon } from 'tombac-icons';
import { ControlsSection } from './SelectedLinkPage.style';
import { useState } from 'react';

interface Props {
  children: React.ReactNode;
  label: React.ReactNode;
}

export const SelectedLinkCollapsibleSection: React.FC<Props> = ({
  children,
  label,
}) => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <ControlsSection style={{ padding: 0 }}>
      <Button
        $borderRadius="0"
        $height="60px"
        $pl="2sp"
        $pr="2sp"
        labelProps={{ $width: '100%' }}
        onClick={() => setCollapsed((prev) => !prev)}
        variant="flat"
      >
        <Box $alignItems="center" $display="flex" $width="100%">
          {label}
          <Box $ml="auto">
            {collapsed ? <ChevronSDownIcon /> : <ChevronSUpIcon />}
          </Box>
        </Box>
      </Button>
      {!collapsed && (
        <Box $p="2sp" $pt="0" $display="flex" $flexDirection="column">
          {children}
        </Box>
      )}
    </ControlsSection>
  );
};
