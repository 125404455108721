import { bearing } from '@turf/turf';
import { useMap } from 'legoland-shared';

interface Props {
  coords: [number, number][];
}

export const SegmentShape: React.FC<Props> = ({ coords }: Props) => {
  const deg2rad = (deg: number) => (deg * Math.PI) / 180;
  const endBearing = deg2rad(
    bearing(coords[coords.length - 2], coords[coords.length - 1]),
  );
  const { map } = useMap();
  const points = coords
    .map((it) => map.project(it as [number, number]))
    .map((it) => [it.x, it.y]);

  // Calculate scale for the segment
  const minX = Math.min(...points.map((c) => c[0]));
  const maxX = Math.max(...points.map((c) => c[0]));
  const minY = Math.min(...points.map((c) => c[1]));
  const maxY = Math.max(...points.map((c) => c[1]));
  const scale = Math.max(maxX - minX, maxY - minY);

  // Dimensions
  const width = (maxX - minX) / scale;
  const height = (maxY - minY) / scale;
  const offsetX = (1 - width) / 2;
  const offsetY = (1 - height) / 2;

  // Scaled
  const scaledPoints = points
    // Scale to fit [0, 1]
    .map((c) => [(c[0] - minX) / scale, (c[1] - minY) / scale])
    // Flip Y axis
    .map((it) => [it[0], it[1]])
    // Offset to center
    .map((it) => [it[0] + offsetX, it[1] + offsetY]);

  const [start, ...rest] = scaledPoints;

  const end = rest[rest.length - 1];

  // Calculate lines to form an arrow at the end
  const arrowRadius = 0.2;
  const arrowDelta = Math.PI + Math.PI / 4;
  const endTip1 = [
    end[0] + Math.sin(endBearing + arrowDelta) * arrowRadius,
    end[1] - Math.cos(endBearing + arrowDelta) * arrowRadius,
  ];
  const endTip2 = [
    end[0] + Math.sin(endBearing - arrowDelta) * arrowRadius,
    end[1] - Math.cos(endBearing - arrowDelta) * arrowRadius,
  ];

  const moveTo = (coord: number[]) => `M${coord[0]},${coord[1]}`;
  const lineTo = (coord: number[]) => `L${coord[0]},${coord[1]}`;

  return (
    <svg
      viewBox="-0.2 -0.2 1.4 1.4"
      width="32px"
      height="32px"
      style={{ flexShrink: 0 }}
    >
      <path
        d={[
          // Segment
          moveTo(start),
          ...rest.map((it) => lineTo(it)),
          // Arrow 1
          moveTo(end),
          lineTo(endTip1),
          // Arrow 2
          moveTo(end),
          lineTo(endTip2),
        ].join(' ')}
        stroke="#666"
        fill="none"
        strokeWidth={0.12}
        strokeLinecap="round"
      />
    </svg>
  );
};
