const modulo = (a: number, b: number) => ((a % b) + b) % b;

export const Angle = {
  rad2deg: (rad: number) => rad * (180 / Math.PI),
  deg2rad: (deg: number) => deg * (Math.PI / 180),
  average: (angles: number[]) => {
    let sinSum = 0;
    let cosSum = 0;
    angles.forEach((angle) => {
      const angleRad = Angle.deg2rad(angle);
      sinSum += Math.sin(angleRad);
      cosSum += Math.cos(angleRad);
    });

    return Angle.rad2deg(Math.atan2(sinSum, cosSum));
  },
  moduloAngle: (a: number) => modulo(a + 180, 360) - 180,
  deltaAngle: (a: number, b: number) => Angle.moduloAngle(a - b),
  absoluteDelta: (a: number, b: number) => Math.abs(Angle.deltaAngle(a, b)),
};
