import { useEffect, useRef } from 'react';

export const useDebouncedEffect = (
  callback: React.EffectCallback,
  delay: number,
  deps: React.DependencyList = [],
) => {
  const data = useRef<any>({ firstTime: true });
  useEffect(() => {
    const { firstTime, clearFunc } = data.current;

    if (firstTime) {
      data.current.firstTime = false;
      return;
    }

    const handler = setTimeout(() => {
      if (clearFunc && typeof clearFunc === 'function') {
        clearFunc();
      }
      data.current.clearFunc = callback();
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [delay, ...deps]);
};
