import { Component } from 'react';
import centroid from '@turf/centroid';
import { featureCollection } from '@turf/helpers';
import mapboxgl, { GeoJSONSource } from 'mapbox-gl';
import { MapStyleMode } from 'legoland-shared';
import { RegionDto } from '../../../model/RegionDto';
import { ValidationStatus } from '../../AnalysisNew/RegionSelection/validation';
import { withMap } from './withMap';
import { COLORS, LAYER_PAINT, RGBA } from 'model/Colors';

type PaintColor = string | mapboxgl.Expression | mapboxgl.StyleFunction;

export interface Props {
  dtoRegions: Array<Readonly<RegionDto>>;
  regionNames?: boolean;
  layerId: string;
  onSelect?: any;
  onRightClick?: any;
  onMouseLeave?: any;
  onMouseMove?: any;
  fillColor?: string;
  fillOpacity?: number;
  outlineColor?: string;
  clickable?: boolean;
  insertBefore?: string;
  map: mapboxgl.Map;
  isEditing?: boolean;
  /**
   * Defines what fill and (out)line color to apply to geojsons so that they are clearly visible on top of the base map.
   */
  mapStyleMode?: MapStyleMode;
}

class DisplayGeojson extends Component<Props> {
  static readonly defaultProps = {
    regionNames: true,
    mapStyleMode: 'light',
  };

  layers: any;
  layersAdded: boolean;

  getNamesVisibility = () => (this.props.regionNames ? 'visible' : 'none');

  private getDefaultFillColor = (mapStyleMode: MapStyleMode): PaintColor => [
    'case',
    ['boolean', ['feature-state', 'hover'], false],
    LAYER_PAINT.regions.hover.fillColor[mapStyleMode],
    ['has', 'fill-color'],
    ['get', 'fill-color'],
    this.props.fillColor || RGBA.grey(1),
  ];

  private getDefaultOutlineColor = (mapStyleMode: MapStyleMode): PaintColor => [
    'case',
    ['boolean', ['feature-state', 'hover'], false],
    LAYER_PAINT.regions.hover.lineColor[mapStyleMode],
    ['get', 'line-color'],
  ];

  constructor(props: Props) {
    super(props);
    this.layers = {
      polygon: `${props.layerId}-polygon`,
      polygonOutline: `${props.layerId}-polygon-outline`,
      polygonName: `${props.layerId}-polygon-name`,
    };
  }

  /**
   * TODO: Describe
   */
  convertRegions = () => {
    const regionsList = this.props.dtoRegions
      .filter((r) => r !== undefined)
      .map((originalRegion: RegionDto, i: number) => {
        const region: any = {
          ...originalRegion,
          id: i,
          properties: {
            ...originalRegion.properties,
            id: i,
          },
        };

        // Default style
        region.properties['fill-opacity'] = 0.2;
        region.properties['line-width'] = 1;
        const validationResult = region.properties.validationResult;
        region.properties['fill-color'] =
          LAYER_PAINT.regions.default.fillColor[this.props.mapStyleMode];
        region.properties['line-color'] =
          LAYER_PAINT.regions.default.lineColor[this.props.mapStyleMode];
        region.properties['text-color'] =
          LAYER_PAINT.regions.default.textColor[this.props.mapStyleMode];
        region.properties['text-halo-color'] =
          LAYER_PAINT.regions.default.textHaloColor[this.props.mapStyleMode];
        if (
          validationResult &&
          validationResult.status === ValidationStatus.INVALID
        ) {
          region.properties['fill-opacity'] = 0.24;
          region.properties['fill-color'] = COLORS.ERROR;
          region.properties['line-color'] = COLORS.ERROR;
        }

        if (this.props.outlineColor) {
          region.properties['line-color'] = this.props.outlineColor;
        }

        // Line style
        if (
          (region.geometry.type === 'LineString' ||
            region.geometry.type === 'MultiLineString') &&
          !this.props.isEditing
        ) {
          region.properties['fill-opacity'] = 0;
          region.properties['line-width'] = 10;
        }

        return region;
      });

    const centersList = regionsList.map((region, i) => {
      const feature = centroid(region, {
        properties: {
          text: region.properties.name,
          'text-color': region.properties['text-color'],
          'text-halo-color': region.properties['text-halo-color'],
          id: i,
        },
      });
      return { id: i, ...feature };
    });
    const regionsCollection = featureCollection(regionsList);
    const centersCollection = featureCollection(centersList);

    return { regionsCollection, centersCollection };
  };

  componentDidMount() {
    const mapbox = this.props.map;
    const { regionsCollection, centersCollection } = this.convertRegions();
    const { polygon, polygonName, polygonOutline } = this.layers;

    mapbox.addSource(polygon, {
      type: 'geojson',
      data: regionsCollection as any,
    });

    mapbox.addSource(polygonName, {
      type: 'geojson',
      data: centersCollection as any,
    });

    if (this.props.onSelect) {
      mapbox.on('click', () => {
        this.props.onSelect(-1);
      });
    }
    mapbox.on('click', polygon, (e: any) => {
      const feature = e.features[0];
      if (this.props.onSelect) {
        this.props.onSelect(feature.id);
      }
    });

    const handleOnRightClick = (e: any) => {
      const feature = e.features[0];
      const coords = [e.lngLat.lng, e.lngLat.lat];
      this.props.onRightClick(feature.id, coords);
    };

    if (this.props.onRightClick) {
      mapbox.on('contextmenu', polygon, handleOnRightClick);
      mapbox.on('contextmenu', polygonOutline, handleOnRightClick);
    }
    mapbox.addLayer(
      {
        id: polygon,
        type: 'fill',
        paint: {
          'fill-color': this.getDefaultFillColor(this.props.mapStyleMode),
          'fill-opacity': this.props.fillOpacity || ['get', 'fill-opacity'],
          'fill-outline-color': COLORS.TRANSPARENT,
        } as any,
        source: polygon,
      },
      this.props.insertBefore,
    );

    const handleOnMouseEnter = () => {
      if (this.props.clickable) {
        mapbox.getCanvas().style.cursor = 'pointer';
      }
    };
    mapbox.on('mouseenter', polygon, handleOnMouseEnter);
    mapbox.on('mouseenter', polygonOutline, handleOnMouseEnter);

    const handleOnMouseLeave = () => {
      if (this.props.clickable) {
        mapbox.getCanvas().style.cursor = '';
      }
      if (this.props.onMouseLeave) {
        this.props.onMouseLeave();
      }
    };
    mapbox.on('mouseleave', polygon, handleOnMouseLeave);
    mapbox.on('mouseleave', polygonOutline, handleOnMouseLeave);

    const handleOnMouseMove = (e: any) => {
      if (this.props.onMouseMove && e.features) {
        const feature = e.features[0];
        this.props.onMouseMove(feature.id);
      }
    };
    mapbox.on('mousemove', polygon, handleOnMouseMove);
    mapbox.on('mousemove', polygonOutline, handleOnMouseMove);

    mapbox.addLayer(
      {
        id: polygonOutline,
        type: 'line',
        paint: {
          'line-color': this.getDefaultOutlineColor(this.props.mapStyleMode),
          'line-width': ['get', 'line-width'],
        },
        layout: {
          'line-cap': 'round',
        },
        source: polygon,
      },
      this.props.insertBefore,
    );

    mapbox.addLayer({
      id: polygonName,
      type: 'symbol',
      layout: {
        'text-field': '{text}',
        'text-font': ['Noto-Regular'],
        'text-offset': [0, 0.1],
        'text-size': 18,
        visibility: this.getNamesVisibility(),
      },
      paint: {
        'text-halo-color': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          LAYER_PAINT.regions.hover.textHaloColor[this.props.mapStyleMode],
          ['has', 'text-halo-color'],
          ['get', 'text-halo-color'],
          LAYER_PAINT.regions.hover.textHaloColor[this.props.mapStyleMode],
        ],
        'text-halo-width': 1.5,
        'text-color': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          LAYER_PAINT.regions.hover.textColor[this.props.mapStyleMode],
          ['has', 'text-color'],
          ['get', 'text-color'],
          LAYER_PAINT.regions.hover.textColor[this.props.mapStyleMode],
        ],
      },
      source: polygonName,
    });

    this.layersAdded = true;
  }

  componentDidUpdate(prevProps: Props) {
    if (!this.layersAdded) {
      setTimeout(() => this.componentDidUpdate(this.props), 500);
      return;
    }

    const mapbox = this.props.map;
    const { polygon, polygonName } = this.layers;

    if (prevProps.regionNames !== this.props.regionNames) {
      mapbox.setLayoutProperty(
        polygonName,
        'visibility',
        this.getNamesVisibility(),
      );
    }

    if (
      this.props.dtoRegions !== prevProps.dtoRegions ||
      this.props.mapStyleMode !== prevProps.mapStyleMode
    ) {
      const { regionsCollection, centersCollection } = this.convertRegions();

      (mapbox.getSource(polygon) as GeoJSONSource)?.setData(
        regionsCollection as any,
      );
      (mapbox.getSource(polygonName) as GeoJSONSource)?.setData(
        centersCollection as any,
      );
    }
  }

  safelyRemove = (layer: string) => {
    const mapbox = this.props.map;
    if (mapbox.getLayer(layer)) {
      mapbox.removeLayer(layer);
    }
  };

  removeSources = () => {
    const mapbox: mapboxgl.Map = this.props.map;
    const { polygon, polygonName } = this.layers;

    if (mapbox.getSource(polygon)) {
      mapbox.removeSource(polygon);
    }
    if (mapbox.getSource(polygonName)) {
      mapbox.removeSource(polygonName);
    }
  };

  componentWillUnmount() {
    const { polygon, polygonName, polygonOutline } = this.layers;
    this.safelyRemove(polygon);
    this.safelyRemove(polygonOutline);
    this.safelyRemove(polygonName);
    this.removeSources();
  }

  render(): null {
    return null;
  }
}
export default withMap(DisplayGeojson);
