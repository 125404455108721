import { useRef, useState, useMemo } from 'react';
import styled from 'styled-components';
import { DisplayRegions } from 'components/Map/Layers/DisplayRegions';
import { useAnalysisContext } from '../AnalysisViewPage';
import ViewPageContent from '../ViewPageContent/ViewPageContent';
import { MapTypes } from 'reducers/menuReducer';
import Title from 'components/Layout/Title';
import GlMap from 'components/Map/GlMap';
import { ZoomInButton } from 'components/UI/UI';
import {
  Box,
  Button,
  FormGroup,
  Heading,
  InlineText,
  Input,
  Label,
  Text,
  ToggleGroup,
} from 'tombac';
import { Link, useLocation } from 'react-router-dom';
import { BackIcon, DownloadIcon } from 'tombac-icons';
import { analysisUrl } from 'logic/analysis/analysisUtils';
import DateTimeSelector from 'components/UI/DateTimeSelector/DateTimeSelector';
import { useScenario } from '../MapFlowsPro/logic/scenario';
import { HStack } from 'components/UI/Stack/Stack';
import { authorizeUrl } from 'api/api';
import { FlowLinksResultFormat } from 'model/AnalysisDto';
import { validateQuery } from './flowLinksQuery';
import { analysisToMapTypeName } from '../utils';

const MapCornerContainer = styled.div`
  width: 490px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background: #fff;
  border-right: solid 1px #e5e5e5;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
`;

const ControlsSection = styled.div<{ white?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: ${(p) => (p.white ? '#FFF' : '#fbfbfb')};
  border-bottom: solid 1px #e5e5e5;
`;

const CodeLabel = styled(Label)`
  background: #0f0f0f28;
  padding: 0 4px;
  margin: 0 4px;
  border: solid 1px #b7b7b7;
  border-radius: 4px;
`;

export const FlowLinksResults = () => {
  const { analysis } = useAnalysisContext();
  const { scenario, setScenario } = useScenario();
  const { search } = useLocation();
  const [query, setQuery] = useState<string>();
  const [queryError, setQueryError] = useState<string>();
  const [resultsFormat, setResultsFormat] = useState<FlowLinksResultFormat>(
    FlowLinksResultFormat.JSON,
  );
  const mapTypeName = useMemo((): MapTypes => analysisToMapTypeName(analysis), [
    analysis,
  ]);

  const backPathname = analysisUrl(
    analysis.info.type,
    analysis.info.id,
    location.pathname.includes('/share/') ? 'share' : 'view',
  );

  const { dateRange: dateRangeId, timeRange: timeRangeId } = scenario;

  const dateRange = analysis.info.timeDefinition.dateRanges[dateRangeId];
  const timeRange = analysis.info.timeDefinition.timeRanges[timeRangeId];

  const resultDto = analysis.results.find(
    (r) =>
      JSON.stringify(r.dateRange) === JSON.stringify(dateRange) &&
      JSON.stringify(r.timeRange) === JSON.stringify(timeRange),
  );

  const regionsNumber = analysis.regions.length;

  const handleQueryChange = (query: string) => {
    const error = validateQuery(query, regionsNumber);
    setQueryError(error);

    setQuery(query);
  };

  return (
    <>
      <ViewPageContent>
        <Title>Flow Links</Title>
        <GlMap
          mapModel={mapTypeName}
          mapControlMenuButtons={
            <ZoomInButton type="regions" regions={analysis.regions} />
          }
          mapOverlayElements={
            <MapCornerContainer>
              <ControlsSection white>
                <Box $display="flex" $justifyContent="space-between">
                  <Link to={backPathname + search}>
                    <Button
                      $ml="-1.5sp"
                      prepend={<BackIcon />}
                      prependedHoverEffect="anim-backward"
                      size="s"
                      variant="flat"
                    >
                      Back
                    </Button>
                  </Link>
                </Box>
                <Text altFont fontSize={16} fontWeight="bold" $mt="1.5sp">
                  {analysis.info.name}
                </Text>
              </ControlsSection>
              <ControlsSection>
                <DateTimeSelector
                  scenario={scenario}
                  onChange={setScenario}
                  analysis={analysis}
                  row
                />
              </ControlsSection>
              <ControlsSection white>
                <Heading $mb="2sp" level={4}>
                  Trips results
                </Heading>
                <InlineText>
                  Query allow to filter paths by selected pattern. Query is a
                  string containing elements separated by
                  <CodeLabel>,</CodeLabel>. Each element can be:
                  <ul>
                    <li>
                      <CodeLabel>number representing region index</CodeLabel>
                    </li>
                    <li>
                      <CodeLabel>*</CodeLabel> - representing any regions
                      indexes (0 or more elements)
                    </li>
                    <li>
                      <CodeLabel>+</CodeLabel> - representing any regions
                      indexes (1 or more elements)
                    </li>
                    <li>
                      <CodeLabel>E</CodeLabel> - representing an external region
                      index.
                    </li>
                  </ul>
                  External region index can be only the first or the last
                  element in the query. If query is not provided all paths will
                  be in results. Example queries:
                  <CodeLabel>E,1,*,2,3</CodeLabel>,
                  <CodeLabel>E,1,+,2,3</CodeLabel>,
                  <CodeLabel>+,1,0,2,*</CodeLabel>
                </InlineText>
                <HStack
                  style={{ marginTop: '16px', justifyContent: 'space-between' }}
                >
                  <FormGroup label="Query" $width="200px" danger={queryError}>
                    <Input
                      placeholder="*"
                      value={query}
                      onChange={(e) => handleQueryChange(e.target.value)}
                      invalid={!!queryError}
                    />
                  </FormGroup>
                  <ToggleGroup.Root
                    label="Results format"
                    onChange={(value) =>
                      setResultsFormat(value as FlowLinksResultFormat)
                    }
                    value={resultsFormat}
                  >
                    <ToggleGroup.Item value="JSON">JSON</ToggleGroup.Item>
                    <ToggleGroup.Item value="PROTOBUF">
                      PROTOBUF
                    </ToggleGroup.Item>
                  </ToggleGroup.Root>
                </HStack>

                <Button
                  $ml="auto"
                  $mt="2sp"
                  as="a"
                  href={prepareUrlWithQuery(
                    resultDto?.TRIPS[resultsFormat],
                    query,
                  )}
                  download
                  prepend={<DownloadIcon />}
                  disabled={!!queryError}
                  variant={'primary'}
                >
                  Download
                </Button>
              </ControlsSection>
            </MapCornerContainer>
          }
        >
          <DisplayRegions layerId="flow-links" dtoRegions={analysis.regions} />
        </GlMap>
      </ViewPageContent>
    </>
  );
};

const prepareUrlWithQuery = (
  link: string | undefined,
  query: string | undefined,
): string => {
  if (link === undefined) {
    throw new Error('Result is undefined');
  }

  let preparedLink;
  if (query === undefined || query === '') {
    preparedLink = link;
  } else {
    const queryParam = encodeURIComponent(query);
    preparedLink = `${link}&query=${queryParam}`;
  }

  return authorizeUrl(preparedLink);
};
