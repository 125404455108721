/* eslint-disable */
import { util, configure, Writer, Reader } from 'protobufjs/minimal';
import Long from 'long';

export const protobufPackage = '';

export interface Point {
  lon: number;
  lat: number;
}

export interface Node {
  parentId?: number | undefined;
  id: number;
  trips: number;
  frc: number;
  geo: Point[];
  processingFailures: number;
  privacyTrims: number;
}

export interface Result {
  nodes: Node[];
}

const basePoint: object = { lon: 0, lat: 0 };

export const Point = {
  encode(message: Point, writer: Writer = Writer.create()): Writer {
    if (message.lon !== 0) {
      writer.uint32(9).double(message.lon);
    }
    if (message.lat !== 0) {
      writer.uint32(17).double(message.lat);
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): Point {
    const reader = input instanceof Reader ? input : new Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...basePoint } as Point;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.lon = reader.double();
          break;
        case 2:
          message.lat = reader.double();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Point {
    const message = { ...basePoint } as Point;
    message.lon =
      object.lon !== undefined && object.lon !== null ? Number(object.lon) : 0;
    message.lat =
      object.lat !== undefined && object.lat !== null ? Number(object.lat) : 0;
    return message;
  },

  toJSON(message: Point): unknown {
    const obj: any = {};
    message.lon !== undefined && (obj.lon = message.lon);
    message.lat !== undefined && (obj.lat = message.lat);
    return obj;
  },

  fromPartial(object: DeepPartial<Point>): Point {
    const message = { ...basePoint } as Point;
    message.lon = object.lon ?? 0;
    message.lat = object.lat ?? 0;
    return message;
  },
};

enum SelectedLinkResultProtoFormat {
  PARENT_ID = 1,
  ID = 2,
  TRIPS = 3,
  GEO = 4,
  FRC = 5,
  PROCESSING_FAILURES = 6,
  PRIVACY_TRIMS = 7,
}

const baseNode: object = { id: 0, trips: 0 };

export const Node = {
  encode(message: Node, writer: Writer = Writer.create()): Writer {
    if (message.parentId !== undefined) {
      writer.uint32(8).uint32(message.parentId);
    }
    if (message.id !== 0) {
      writer.uint32(16).uint32(message.id);
    }
    if (message.trips !== 0) {
      writer.uint32(24).int32(message.trips);
    }
    if (message.frc !== undefined) {
      writer.uint32(8).uint32(message.frc);
    }
    for (const v of message.geo) {
      Point.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): Node {
    const reader = input instanceof Reader ? input : new Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseNode } as Node;
    message.geo = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case SelectedLinkResultProtoFormat.PARENT_ID:
          message.parentId = reader.uint32();
          break;
        case SelectedLinkResultProtoFormat.ID:
          message.id = reader.uint32();
          break;
        case SelectedLinkResultProtoFormat.TRIPS:
          message.trips = reader.int32();
          break;
        case SelectedLinkResultProtoFormat.GEO:
          message.geo.push(Point.decode(reader, reader.uint32()));
          break;
        case SelectedLinkResultProtoFormat.FRC:
          message.frc = reader.uint32();
          break;
        case SelectedLinkResultProtoFormat.PROCESSING_FAILURES:
          message.processingFailures = reader.uint32();
          break;
        case SelectedLinkResultProtoFormat.PRIVACY_TRIMS:
          message.privacyTrims = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Node {
    const message = { ...baseNode } as Node;
    message.parentId =
      object.parentId !== undefined && object.parentId !== null
        ? Number(object.parentId)
        : undefined;
    message.id =
      object.id !== undefined && object.id !== null ? Number(object.id) : 0;
    message.trips =
      object.trips !== undefined && object.trips !== null
        ? Number(object.trips)
        : 0;
    message.geo = (object.geo ?? []).map((e: any) => Point.fromJSON(e));
    return message;
  },

  toJSON(message: Node): unknown {
    const obj: any = {};
    message.parentId !== undefined && (obj.parentId = message.parentId);
    message.id !== undefined && (obj.id = message.id);
    message.trips !== undefined && (obj.trips = message.trips);
    if (message.geo) {
      obj.geo = message.geo.map((e) => (e ? Point.toJSON(e) : undefined));
    } else {
      obj.geo = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<Node>): Node {
    const message = { ...baseNode } as Node;
    message.parentId = object.parentId ?? undefined;
    message.id = object.id ?? 0;
    message.trips = object.trips ?? 0;
    message.geo = (object.geo ?? []).map((e) => Point.fromPartial(e));
    return message;
  },
};

const baseResult: object = {};

export const Result = {
  encode(message: Result, writer: Writer = Writer.create()): Writer {
    for (const v of message.nodes) {
      Node.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): Result {
    const reader = input instanceof Reader ? input : new Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseResult } as Result;
    message.nodes = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.nodes.push(Node.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Result {
    const message = { ...baseResult } as Result;
    message.nodes = (object.nodes ?? []).map((e: any) => Node.fromJSON(e));
    return message;
  },

  toJSON(message: Result): unknown {
    const obj: any = {};
    if (message.nodes) {
      obj.nodes = message.nodes.map((e) => (e ? Node.toJSON(e) : undefined));
    } else {
      obj.nodes = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<Result>): Result {
    const message = { ...baseResult } as Result;
    message.nodes = (object.nodes ?? []).map((e) => Node.fromPartial(e));
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;
export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

// If you get a compile-error about 'Constructor<Long> and ... have no overlap',
// add '--ts_proto_opt=esModuleInterop=true' as a flag when calling 'protoc'.
if (util.Long !== Long) {
  util.Long = Long as any;
  configure();
}
