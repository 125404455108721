import { ReverseGeocodeApi } from 'api/ReverseGeocodeApi';
import { useEffect, useState } from 'react';
import { Caption, TooltipOnHover } from 'tombac';

interface Props {
  coords: [number, number];
}

export const SegmentName: React.FC<Props> = ({ coords }: Props) => {
  const [name, setName] = useState();

  useEffect(() => {
    const request = async () => {
      const name = await ReverseGeocodeApi.getRoadName(coords);

      setName(name ?? 'Segment');
    };

    request();
  }, [coords[0], coords[1]]);

  return (
    <TooltipOnHover
      placement="top"
      size="s"
      content={<Caption>{name}</Caption>}
    >
      {name}
    </TooltipOnHover>
  );
};
