import { Geometry } from '@turf/turf';
import { MapTypes } from 'reducers/menuReducer';
import { useMapStyleSettings } from 'reducers/mapStyleSettingsReducer';
import ResultSpinner from 'components/UI/ResultSpinner/ResultSpinner';
import { ZoomInButton } from 'components/UI/UI';
import Immutable from 'immutable';
import * as React from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import GlMap from '../../Map/GlMap';
import MapScreenshot from 'components/Map/MapScreenshot';
import { centerOnRegions } from '../../Map/mapUtils';
import DateTimeSelector from '../../UI/DateTimeSelector/DateTimeSelector';
import { useAnalysisContext } from '../AnalysisViewPage';
import { PartialResult } from '../MapFlowsPro/logic/PartialResult';
import { useScenario } from '../MapFlowsPro/logic/scenario';
import ViewPageNavbar from '../NavBar/ViewPageNavbar';
import { analysisToMapTypeName, mapboxBbox } from '../utils';
import ViewPageContent from '../ViewPageContent/ViewPageContent';
import { MapFlowsControls } from './MapFlowsControls';
import './MapFlows.css';
import MapFlowsDetails from './MapFlowsDetails';
import { MapFlowsLayer } from './MapFlowsLayer';
import syncMove from './sync-move';

const copyMapPosition = (from: any, to: any) => {
  const center = from.getCenter();
  const zoom = from.getZoom();
  const bearing = from.getBearing();
  const pitch = from.getPitch();
  to.jumpTo({
    center,
    zoom,
    bearing,
    pitch,
  });
};

export const MapFlows: React.FC = () => {
  const [
    _mapStyleSettings,
    _setMapStyleSettings,
    { mapStyleMode },
  ] = useMapStyleSettings();
  const { analysis } = useAnalysisContext();

  const regions = analysis.regions;
  const hasExternals = analysis.info.passMatrix !== true;
  const mapboxRef = useRef<mapboxgl.Map>(undefined);
  const mapboxComparisonRef = useRef<mapboxgl.Map>(undefined);
  const mapTypeName = React.useMemo(
    (): MapTypes => analysisToMapTypeName(analysis),
    [analysis],
  );
  React.useEffect(() => {
    centerOnRegions(mapboxRef.current, regions);
  }, [regions]);

  const [selectedFrom, setSelectedFrom] = useState(-1);
  const [selectedTo, setSelectedTo] = useState(-1);
  const [labels, setLabels] = useState(true);

  const allRegions = useMemo(() => {
    const regionIds = analysis.regions.map((_, i) => i);
    return hasExternals ? [...regionIds, analysis.regions.length] : regionIds;
  }, [analysis.regions, hasExternals]);

  const { scenario, setScenario } = useScenario();
  const scenarioWithRegions = useMemo(() => {
    const isVia = selectedTo !== -1 && selectedFrom !== -1;
    const origins = isVia ? [selectedFrom] : allRegions;
    const destinations = isVia ? [selectedTo] : allRegions;
    const vias = isVia ? allRegions : [];
    return {
      ...scenario,
      origins: Immutable.Set(origins),
      destinations: Immutable.Set(destinations),
      vias: Immutable.Set(vias),
    };
  }, [scenario, selectedTo, selectedFrom]);

  const { result, compareResult, loading } = PartialResult.use(
    analysis,
    scenarioWithRegions,
  );

  const [hovered, setHovered] = useState(-1);
  const [flowsPerKm, setFlowsPerKm] = useState(false);

  const compare = scenario.dateRangeCompare !== undefined;

  const zoomToRegion = (geometry: Geometry) => {
    const map = mapboxRef.current;
    if (map) {
      map.fitBounds(mapboxBbox(geometry), { padding: 100 });
    }
  };

  useEffect(() => {
    if (!compare || !mapboxComparisonRef.current) {
      return;
    }

    let off: any;
    const syncViews = () => {
      window.dispatchEvent(new Event('resize'));
      copyMapPosition(mapboxRef.current, mapboxComparisonRef.current);
      off = syncMove(mapboxRef.current, mapboxComparisonRef.current).off;
    };
    syncViews();
    return () => {
      if (off) {
        off();
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
        }, 0);
      }
    };
  }, [compare, compareResult]);

  useEffect(() => {
    setHovered(undefined);
  }, [selectedFrom, selectedTo]);

  const mapLayersMenuContent = (
    <MapFlowsControls
      selectedFrom={selectedFrom}
      selectedTo={selectedTo}
      labels={labels}
      setLabels={setLabels}
      flowsPerKm={flowsPerKm}
      setFlowsPerKm={setFlowsPerKm}
    />
  );

  return (
    <>
      <ViewPageNavbar visualisationName="Map Flows" />
      <ViewPageContent>
        <>
          {loading && <ResultSpinner subtitle="Loading results..." map />}
          <DateTimeSelector
            scenario={scenario}
            onChange={setScenario}
            analysis={analysis}
            map
            comparable
          />
          <div
            style={{
              display: 'flex',
              width: scenario.dateRangeCompare !== undefined ? '50%' : '100%',
            }}
          >
            <GlMap
              onMapRefChange={(c) => (mapboxRef.current = c)}
              mapModel={mapTypeName}
              hideNavigationControls={!!compareResult}
              hideMapControlMenuButtons={!!compare}
              mapControlMenuButtons={
                <>
                  <ZoomInButton type="regions" regions={analysis.regions} />
                  <MapScreenshot map={mapboxRef.current} />
                </>
              }
              mapControlsProps={{ mapLayersMenuContent }}
              mapOverlayElements={
                <MapFlowsDetails
                  selectedFrom={selectedFrom}
                  selectedTo={selectedTo}
                  result={result}
                  dtoRegions={regions}
                  hovered={hovered}
                  onHover={setHovered}
                  onOrigin={setSelectedFrom}
                  onDestination={setSelectedTo}
                  hasExternals={hasExternals}
                  hideable={compare}
                  zoomToRegion={zoomToRegion}
                />
              }
            >
              {result && (
                <>
                  <MapFlowsLayer
                    selectedFrom={selectedFrom}
                    selectedTo={selectedTo}
                    regions={regions}
                    result={result}
                    labels={labels}
                    flowsPerKm={flowsPerKm}
                    hovered={hovered}
                    onHover={setHovered}
                    hideLegend={compare}
                    setFromId={setSelectedFrom}
                    setToId={setSelectedTo}
                    mapStyleMode={mapStyleMode}
                  />
                </>
              )}
            </GlMap>
          </div>
          {compareResult && (
            <div style={{ display: 'flex', width: compare ? '50%' : '100%' }}>
              <GlMap
                onMapRefChange={(c) => (mapboxComparisonRef.current = c)}
                mapModel={mapTypeName}
                mapControlsProps={{ mapLayersMenuContent }}
                mapControlMenuButtons={
                  <>
                    <ZoomInButton type="regions" regions={analysis.regions} />
                    <MapScreenshot map={mapboxRef.current} />
                  </>
                }
              >
                {compareResult && (
                  <MapFlowsLayer
                    selectedFrom={selectedFrom}
                    selectedTo={selectedTo}
                    regions={regions}
                    result={compareResult}
                    labels={labels}
                    flowsPerKm={flowsPerKm}
                    hovered={hovered}
                    onHover={setHovered}
                    setFromId={setSelectedFrom}
                    setToId={setSelectedTo}
                    mapStyleMode={mapStyleMode}
                  />
                )}
              </GlMap>
            </div>
          )}
        </>
      </ViewPageContent>
    </>
  );
};
