import styled from 'styled-components';
import { Box, Button, Text, tombac } from 'tombac';
import { HideIcon, ShowIcon } from 'tombac-icons';
import { NodeFilter } from '../SelectedLinkTree';
import { DefaultText, GreyText } from 'components/UI/Text.style';
import { Path } from './LinkRegionInfo';

const SegmentBox = styled.div`
  border: 1px solid #eee;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
  font-family: ${tombac.fontFamily};
  height: 32px;
  padding: 0 16px;
  padding-right: 0;

  :hover {
    background: #f2f2f2;
  }
`;

interface Props {
  nodeFilter: NodeFilter;
  setNodeFilter: (nodeFilter: NodeFilter) => void;
  setHover: (h: any) => void;
  path: Path;
  index: number;
}

export const SegmentInfo: React.FC<Props> = ({
  nodeFilter,
  setNodeFilter,
  setHover,
  path,
  index,
}: Props) => {
  return (
    <SegmentBox
      key={path.index}
      onMouseEnter={() => setHover({ nodes: new Set([path.node]) })}
      onMouseLeave={() => setHover({ nodes: new Set() })}
      style={{
        opacity: nodeFilter.exclude.has(path.node) ? 0.5 : 1,
      }}
    >
      <Box $width="2sp">{index + 1}</Box>
      <div
        style={{
          height: 4 + path.style['line-width'] + 'px',
          width: '60px',
          borderRadius: '10px',
          margin: '0 10px',
          border: '1px solid #fff',
          backgroundColor: !nodeFilter.exclude.has(path.node)
            ? path.style['line-color']
            : '#ddd',
        }}
      />
      <DefaultText $width="100px">
        {path.style.trips.toLocaleString()} trips
      </DefaultText>
      <GreyText>{path.style.percent.toFixed(2)}%</GreyText>
      <Button
        $ml="auto"
        $mr="0.5sp"
        onClick={() => {
          const hasNode = nodeFilter.exclude.has(path.node);
          if (hasNode) {
            nodeFilter.exclude.delete(path.node);
          } else {
            nodeFilter.exclude.add(path.node);
          }

          setNodeFilter({ ...nodeFilter });
        }}
        shape="circle"
        size="xs"
        variant="flat"
        theme={{ ripple: { fill: '--white' } }}
      >
        <Text $color="inherit" as="span">
          {nodeFilter.exclude.has(path.node) ? <HideIcon /> : <ShowIcon />}
        </Text>
      </Button>
    </SegmentBox>
  );
};
