import { SelectedLinkResultFormat } from 'api/AnalysisApi';
import { fetchApi } from 'api/api';
import { CreateCache } from 'logic/storage/Cache';
import { Analysis } from 'model/AnalysisDto';
import { useEffect, useState } from 'react';
import { LinkNode, TreeType } from './LinkNode';
import { Node, Result } from './result';
import { countRegionMaxTrips } from './utils';

const makeNode = (
  { trips, geo, frc, processingFailures, privacyTrims }: Partial<Node>,
  type: TreeType,
): LinkNode => {
  if (trips === undefined || frc === undefined || geo === undefined) {
    throw new Error('Invalid node data');
  }
  const coords = geo.map((it) => [it.lon, it.lat]);
  return new LinkNode({
    trips,
    frc,
    processingFailures,
    privacyTrims,
    coords,
    type,
  });
};

const cache = CreateCache((it) => String(it), 2);

export function useSelectedLinkResult(
  analysis: Analysis,
  dateRangeId: number,
  timeRangeId: number,
  type: TreeType,
): LinkNode | undefined {
  const [resultTree, setResultTree] = useState<LinkNode | undefined>();

  useEffect(() => {
    const get = async () => {
      const cacheId = [analysis.info.id, dateRangeId, timeRangeId, type].join(
        '-',
      );
      if (cache.has(cacheId)) {
        setResultTree(cache.get(cacheId) as any);
        return;
      }
      const dateRange = analysis.info.timeDefinition.dateRanges[dateRangeId];
      const timeRange = analysis.info.timeDefinition.timeRanges[timeRangeId];
      const resultDto = analysis.results.find(
        (r) =>
          JSON.stringify(r.dateRange) === JSON.stringify(dateRange) &&
          JSON.stringify(r.timeRange) === JSON.stringify(timeRange),
      );

      const protobufLink =
        type === TreeType.In
          ? resultDto?.incomingResultUrl.get(SelectedLinkResultFormat.PROTOBUF)
          : resultDto?.outgoingResultUrl.get(SelectedLinkResultFormat.PROTOBUF);

      if (!protobufLink) {
        throw new Error('Results link is undefined');
      }
      const url = new URL(protobufLink);

      const response = await fetchApi<any>(
        url.toString(),
        {},
        'blob',
      ).then((it) => it.arrayBuffer());

      const result = Result.decode(new Uint8Array(response));

      if (result.nodes.length === 0) {
        setResultTree(
          makeNode(
            {
              trips: 0,
              geo: (analysis.regions[0].geometry.coordinates as any).map(
                (it: number[]) => ({
                  lon: it[0],
                  lat: it[1],
                }),
              ),
            },
            type,
          ),
        );
        return;
      }

      const nodeById = new Map<number, LinkNode>();

      result.nodes.forEach((it) => {
        const node = makeNode(it, type);
        nodeById.set(it.id, node);
      });

      const root = haveMoreThanOneRootPath(result)
        ? buildRegionTree(result, nodeById, type)
        : buildLinkTree(result, nodeById);

      root.postConstruct();

      cache.add(cacheId, root);

      setResultTree(root);
    };

    setResultTree(undefined);
    get();
  }, [analysis.info.id, timeRangeId, dateRangeId, type]);

  return resultTree;
}

const buildLinkTree = (
  result: Result,
  nodeById: Map<number, LinkNode>,
): LinkNode => {
  result.nodes.forEach((it) => {
    if (it.parentId === undefined) return; // skip root
    const parent = nodeById.get(it.parentId);
    const node = nodeById.get(it.id) as LinkNode;
    parent?.children.push(node);
  });

  const rootNode = nodeById.get(0) as LinkNode;
  return rootNode;
};

const buildRegionTree = (
  result: Result,
  nodeById: Map<number, LinkNode>,
  type: TreeType,
): LinkNode => {
  //mock node to merge all region incoming/outgoing root paths
  const mockRoot = makeNode(
    {
      trips: countRegionMaxTrips(result.nodes),
      geo: [
        { lat: 0, lon: 0 },
        { lat: 1, lon: 1 },
      ],
      frc: 1,
    },
    type,
  );

  result.nodes.forEach((it) => {
    if (it.parentId === undefined) {
      const parent = mockRoot;
      const node = nodeById.get(it.id) as LinkNode;
      parent.children.push(node);
      return;
    }
    const parent = nodeById.get(it.parentId);
    const node = nodeById.get(it.id) as LinkNode;
    parent?.children.push(node);
  });

  return mockRoot;
};

//check if there is a region or link analysis
const haveMoreThanOneRootPath = (result: Result) => {
  const rootNodes = result.nodes.filter((it) => it.parentId === undefined);

  return rootNodes.length > 1;
};
