import { useEffect } from 'react';
import { useMap } from 'legoland-shared';

export const usePaintProperty = (layer: string, name: string, value: any) => {
  const { map } = useMap();

  useEffect(() => {
    map.setPaintProperty(layer, name, value);
  }, [layer, name, value]);
};
