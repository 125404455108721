import { SelectedLinkResultFormat } from 'api/AnalysisApi';
import { authorizeUrl } from 'api/api';
import { Scenario } from 'components/AnalysisViewPage/MapFlowsPro/logic/scenario';
import { Analysis } from 'model/AnalysisDto';
import { Box, Button, FormGroup, Tooltip } from 'tombac';
import { DownloadIcon } from 'tombac-icons';

interface DownloadButtonsProps {
  links: Map<SelectedLinkResultFormat, string>;
  close: () => void;
}

const DownloadButtons: React.FC<DownloadButtonsProps> = ({ links, close }) => {
  return (
    <>
      {[
        SelectedLinkResultFormat.CSV,
        SelectedLinkResultFormat.SHAPEFILE,
        SelectedLinkResultFormat.JSON,
      ].map((formatName) => {
        const link = links.get(formatName);
        return (
          link && (
            <Button
              key={formatName}
              as="a"
              href={authorizeUrl(link)}
              download
              $width="100%"
              size="s"
              variant="flat"
              onClick={() => close()}
            >
              .{formatName}
            </Button>
          )
        );
      })}
    </>
  );
};

interface DownloadSelectedLinkProps {
  scenario: Scenario;
  analysis: Analysis;
}

export const DownloadSelectedLink: React.FC<DownloadSelectedLinkProps> = ({
  scenario,
  analysis,
}) => {
  const { dateRange: dateRangeId, timeRange: timeRangeId } = scenario;

  const dateRange = analysis.info.timeDefinition.dateRanges[dateRangeId];
  const timeRange = analysis.info.timeDefinition.timeRanges[timeRangeId];
  const resultDto = analysis.results.find(
    (r) =>
      JSON.stringify(r.dateRange) === JSON.stringify(dateRange) &&
      JSON.stringify(r.timeRange) === JSON.stringify(timeRange),
  );

  if (!resultDto) {
    return null;
  }

  return (
    <>
      <Tooltip
        placement="bottom"
        content={({ close }) => (
          <Box $width="290px" $flexDirection="row" $display="flex" $m="-16px">
            <FormGroup label="Incoming trips" $p="16px">
              <Box>
                <DownloadButtons
                  links={resultDto.incomingResultUrl}
                  close={close}
                />
              </Box>
            </FormGroup>
            <Box $width="1px" $alignItems="stretch" $backgroundColor="#eee" />
            <FormGroup label="Outgoing trips" $p="16px">
              <Box>
                <DownloadButtons
                  links={resultDto.outgoingResultUrl}
                  close={close}
                />
              </Box>
            </FormGroup>
          </Box>
        )}
      >
        {({ ref, toggle }) => (
          <Button
            $ml="auto"
            ref={ref}
            prepend={<DownloadIcon />}
            variant={'flat'}
            size="s"
            onClick={() => {
              toggle();
            }}
          >
            Download
          </Button>
        )}
      </Tooltip>
    </>
  );
};
