import { useMap } from 'legoland-shared';

export const withMap = (Component: any) => {
  // eslint-disable-next-line react/display-name
  return (props: any) => {
    const { map } = useMap();
    if (!map) return null;
    return <Component map={map} {...props} />;
  };
};
