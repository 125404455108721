import { GreyText } from 'components/UI/Text.style';
import { Box } from 'tombac';
import { SegmentShape } from './SegmentShape';

interface Props {
  coords: [number, number][];
}

export const Bearing: React.FC<Props> = ({ coords }: Props) => {
  return (
    <Box $display="flex" $alignItems="center" $ml="auto">
      <SegmentShape coords={coords} />
      <GreyText>Direction</GreyText>
    </Box>
  );
};
