import { useEffect, useState } from 'react';
import * as React from 'react';
import { useDebouncedEffect } from './useDebouncedEffect';
import { useThrottledEffect } from './useThrottledEffect';

interface UseFrequentValueOptions<T> {
  debounce?: number;
  throttle?: number;
  preprocess?: (v: T) => T;
}

export function useFrequentValue<T>(
  value: T,
  { debounce, throttle, preprocess = (x) => x }: UseFrequentValueOptions<T>,
  onChange?: (v: T) => void,
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [state, setState] = useState(value);

  useEffect(() => {
    setState(value);
  }, [value]);

  const useTimedEffect =
    debounce !== undefined ? useDebouncedEffect : useThrottledEffect;
  const time = debounce !== undefined ? debounce : throttle;

  if (!time) {
    throw new Error('Debounce or throttle have to be defined');
  }

  useTimedEffect(
    () => {
      const preprocessedState = preprocess(state);
      if (JSON.stringify(preprocessedState) !== JSON.stringify(value)) {
        onChange?.(preprocessedState);
      }
    },
    time,
    [state],
  );

  return [state, setState];
}
