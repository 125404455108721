import { useMemo } from 'react';
import { lineString } from '@turf/turf';
import { Box, Button, HFlex } from 'tombac';
import { CancelIcon, ForwardIcon } from 'tombac-icons';
import { TreeType } from '../LinkNode';
import { SegmentName } from './SegmentName';
import { SelectedSegment } from '../SelectedLinkTree';
import {
  BoldText,
  BoldTextOverflow,
  DefaultText,
  GreyText,
} from 'components/UI/Text.style';
import { RoadClass } from './RoadClass';
import { lineStringToReverseGeocodePoint } from 'components/Map/mapUtils';
import { Bearing } from './Bearing';

interface Props {
  treeType: TreeType;
  maxTrips: number;
  segment: any;
  setSelectedSegments: (segments: SelectedSegment[]) => void;
  selectedSegments: SelectedSegment[];
  isSelectedLinkRegion: boolean;
}

export const SelectedLinkInfoHeader: React.FC<Props> = ({
  treeType,
  segment,
  maxTrips,
  setSelectedSegments,
  selectedSegments,
  isSelectedLinkRegion,
}: Props) => {
  const removeSegment = (index: number) => {
    setSelectedSegments(selectedSegments.filter((_, i) => index !== i));
  };

  const linkName = isSelectedLinkRegion ? 'Region' : 'Link';

  const segmentCordsCenter = useMemo(() => {
    return lineStringToReverseGeocodePoint(lineString(segment.coords));
  }, [segment]);

  return (
    <Box
      $display="flex"
      $alignItems="center"
      $position="relative"
      $borderBottom="solid 1px #e5e5e5"
      $p="1sp 2sp"
    >
      <Box $width="100%">
        <HFlex $alignItems="center">
          {treeType === TreeType.In ? (
            <>
              <BoldTextOverflow $maxWidth="225px">
                <SegmentName coords={segmentCordsCenter} />
              </BoldTextOverflow>
              <ForwardIcon />
              <BoldText $color="rgba(0, 170, 255, 1)">{linkName}</BoldText>
            </>
          ) : (
            <>
              <BoldText $color="rgba(0, 170, 255, 1)">{linkName}</BoldText>
              <ForwardIcon />
              <BoldTextOverflow $maxWidth="225px">
                <SegmentName coords={segmentCordsCenter} />
              </BoldTextOverflow>
            </>
          )}
          <Box $ml="0.5sp">
            <RoadClass frc={segment.frc} />
          </Box>
        </HFlex>

        <HFlex $justifyContent="space-between">
          <HFlex $alignItems="center" $gap="2px">
            <DefaultText>Total trips:</DefaultText>
            <BoldText>{segment.totalTrips.toLocaleString()}</BoldText>
            <GreyText $ml="1sp">
              {((segment.totalTrips / maxTrips) * 100).toFixed(2)}%
            </GreyText>
          </HFlex>
          <Bearing coords={segment.coords} />
        </HFlex>
      </Box>

      <Box $position="absolute" $top="0" $right="0">
        <Button
          onClick={() => removeSegment(segment.index)}
          shape="circle"
          size="s"
          variant="flat"
        >
          <CancelIcon />
        </Button>
      </Box>
    </Box>
  );
};
