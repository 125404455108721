import * as React from 'react';
import { featureCollection } from '@turf/helpers';
import centroid from '@turf/centroid';
import { Button } from 'tombac';
import { useMap, MapStyleMode } from 'legoland-shared';
import { useFeatureState } from 'hooks/useFeatureState';
import useLayers from 'hooks/useLayers';
import { useLayerEvent } from 'hooks/useLayerEvent';
import { useLayoutProperty } from 'hooks/useLayoutProperty';
import { useMapEvent } from 'hooks/useMapEvent';
import { usePaintProperty } from 'hooks/usePaintProperty';
import { normalize, minMax, clamp, sum } from 'logic/math';
import { ReadableResult } from 'logic/ReadableResult';
import { LAYER_PAINT, RGBA } from 'model/Colors';
import { RegionDto } from 'model/RegionDto';
import { isLayerLineString } from 'components/Map/mapUtils';
import Popup from '../../Map/Layers/Popup';
import { colorFillScale, colorTextScale, rangeCount } from './colorScale';
import ColorLegend from './ColorLegend';
import { calculateArea } from './calculateArea';
import './MapFlows.css';

interface Popup {
  index: number;
  coords: number[];
}

const regionName = (regions: RegionDto[], index: number) => {
  if (index > regions.length - 1) {
    return 'External';
  }
  return regions[index].properties.name;
};

interface MapFlowsLayersProps {
  regions: RegionDto[];
  selectedFrom?: number;
  selectedTo?: number;
  onHover: (id: number) => void;
  result?: ReadableResult;
  labels?: boolean;
  flowsPerKm?: boolean;
  hovered?: number;
  hideLegend?: boolean;
  setFromId?: (id: number) => void;
  setToId?: (id: number) => void;
  mapStyleMode: MapStyleMode;
}

const getRegionLayers = (mapStyleMode: MapStyleMode): mapboxgl.Layer[] => [
  {
    id: 'polygon-fill',
    type: 'fill',
    paint: {
      'fill-color': LAYER_PAINT.mapFlows.default.fillColor[mapStyleMode],
      'fill-opacity': ['case', isLayerLineString, 0, 0.65],
      'fill-outline-color': RGBA.black(0),
    },
  },
  {
    id: 'polygon-outline',
    type: 'line',
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    } as any,
    paint: {
      'line-color': [
        'case',
        ['==', ['feature-state', 'hovered'], true],
        LAYER_PAINT.mapFlows.hover.lineColor[mapStyleMode],
        isLayerLineString,
        mapStyleMode === 'light' ? RGBA.black(0.5) : RGBA.white(0.5),
        LAYER_PAINT.mapFlows.default.lineColor[mapStyleMode],
      ],
      'line-width': ['case', isLayerLineString, 7, 1.5],
    },
  },
];

const getCenterLayers = (mapStyleMode: MapStyleMode): mapboxgl.Layer[] => [
  {
    id: 'polygon-name',
    type: 'symbol',
    layout: {
      'text-field': '{text}',
      'text-font': ['Noto-Regular'],
      'text-offset': [0, 0.1],
      'text-size': ['get', 'text-size'],
      visibility: 'visible',
    } as any,
    paint: {
      'text-halo-color': [
        'case',
        ['==', ['feature-state', 'hovered'], true],
        LAYER_PAINT.mapFlows.hover.textHaloColor[mapStyleMode],
        LAYER_PAINT.mapFlows.default.textHaloColor[mapStyleMode],
      ],
      'text-halo-width': 1.5,
      'text-color': [
        'case',
        ['==', ['feature-state', 'hovered'], true],
        LAYER_PAINT.mapFlows.hover.textColor[mapStyleMode],
        ['has', 'text-color'],
        ['get', 'text-color'],
        LAYER_PAINT.mapFlows.default.textColor[mapStyleMode],
      ],
    },
  },
];

const getRegionAsLineStringLayer = (mapStyleMode: MapStyleMode): mapboxgl.Layer[] => [
  {
    id: 'line-string-border',
    type: 'line',
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    } as any,
    paint: {
      'line-color': mapStyleMode === 'light' ? RGBA.black(0.5) : RGBA.white(0.5),
      'line-width': 1,
      'line-gap-width': 7,
    },
  },
];

export const MapFlowsLayer: React.FC<MapFlowsLayersProps> = (props) => {
  const origin = React.useMemo(() => props.selectedFrom, [props.selectedFrom]);
  const destination = React.useMemo(() => props.selectedTo, [props.selectedTo]);
  const [popup, setPopup] = React.useState<undefined | Popup>(undefined);

  const regionCollection = React.useMemo(
    () => featureCollection(props.regions.map((f, i) => ({ ...f, id: '' + i }))),
    [props.regions],
  );

  const regionsArea = React.useMemo(() => props.regions.map(calculateArea), [props.regions]);

  const [percent, normalizedFlows] = React.useMemo(() => {
    const { regions, selectedFrom, selectedTo, result, flowsPerKm } = props;

    if (selectedFrom === -1 && selectedTo === -1) {
      return [undefined, undefined];
    }

    const isVia = selectedFrom > -1 && selectedTo > -1;
    const oneToMany = selectedFrom > -1 && selectedTo === -1;
    const manyToOne = selectedFrom === -1 && selectedTo > -1;

    const absoluteFlows = regions.map((region, id) => {
      if (id === selectedFrom || id === selectedTo) {
        return 0;
      }

      if (isVia) {
        return result.get(selectedFrom, selectedTo, id).trips;
      }

      let areaDivisor = 1;
      if (flowsPerKm) {
        areaDivisor = regionsArea[id];
      }
      if (oneToMany) {
        return result.get(selectedFrom, id).trips / areaDivisor;
      }
      if (manyToOne) {
        return result.get(id, selectedTo).trips / areaDivisor;
      }
    });

    const flowSum = isVia ? result.get(selectedFrom, selectedTo).trips : sum(absoluteFlows);

    const percent = absoluteFlows.map((x) => (flowSum === 0 ? 0 : (x / flowSum) * 100));

    return [percent, normalize(absoluteFlows)];
  }, [
    props.regions,
    props.selectedFrom,
    props.selectedTo,
    props.result,
    props.flowsPerKm,
    regionsArea,
  ]);

  const internalFlows = React.useMemo(
    () => normalize(props.regions.map((r, i) => props.result.get(i, i).trips)),
    [props.regions, props.result],
  );

  const getFillExpression = React.useCallback(
    (property: 'fillColor' | 'lineColor') => {
      if (normalizedFlows === undefined) {
        return [
          'case',
          ['==', ['feature-state', 'hovered'], true],
          LAYER_PAINT.mapFlows.hover[property][props.mapStyleMode],
          LAYER_PAINT.mapFlows.default[property][props.mapStyleMode],
        ];
      }

      const noFlowsColor = props.mapStyleMode === 'light' ? RGBA.black(0.05) : RGBA.white(0.05);
      const fillValues: any[] = normalizedFlows
        .map((flows) => (flows === 0 ? noFlowsColor : colorFillScale(flows)))
        .map((color, id) => [id, color])
        .filter(([id]) => id !== origin && id !== destination)
        .reduce((a, b) => (a.push(...b), a), []);
      return ['match', ['get', 'i'], ...fillValues, RGBA.grey(1)];
    },
    [normalizedFlows, origin, destination, props.mapStyleMode],
  );

  const centersList = React.useMemo(() => props.regions.map((region) => centroid(region)), [
    props.regions,
  ]);

  const textFeatures = React.useMemo(
    () =>
      featureCollection(
        props.regions.map((region, id) => {
          const scaleTextSize = (x: number) => 10 + 4 * (clamp(0, 100, x) / 100);
          const textSize =
            normalizedFlows === undefined
              ? scaleTextSize(internalFlows[id])
              : scaleTextSize(normalizedFlows[id]);

          const resultTextColor =
            normalizedFlows && props.mapStyleMode === 'light'
              ? colorTextScale(normalizedFlows[id])
              : RGBA.white(0.9);
          const resultTextHaloColor =
            !normalizedFlows || props.mapStyleMode === 'light'
              ? RGBA.white(0.9)
              : colorTextScale(normalizedFlows[id]);

          const textColor =
            normalizedFlows === undefined
              ? LAYER_PAINT.mapFlows.default.textColor[props.mapStyleMode]
              : resultTextColor;
          const textHaloColor =
            normalizedFlows === undefined
              ? LAYER_PAINT.mapFlows.default.textHaloColor[props.mapStyleMode]
              : resultTextHaloColor;

          const isSelected = id === origin || id === destination;
          const selectedStyle = {
            'text-size': 13,
            'text-color': LAYER_PAINT.mapFlows.selected[props.mapStyleMode],
          };

          let text = region.properties.name;
          if (normalizedFlows) {
            if (isSelected) {
              const role =
                origin === id && destination === id
                  ? 'Origin & Destination'
                  : origin === id
                  ? 'Origin'
                  : 'Destination';
              text = role + '\n' + region.properties.name;
            } else {
              text = percent[id] === 0 ? '' : percent[id].toFixed(2) + '%';
            }
          }

          return {
            ...centersList[id],
            id,
            properties: {
              text,
              'text-size': textSize,
              'text-color': textColor,
              'text-halo-color': textHaloColor,
              ...(isSelected ? selectedStyle : {}),
            },
          };
        }),
      ),
    [
      props.regions,
      props.mapStyleMode,
      origin,
      destination,
      centersList,
      normalizedFlows,
      internalFlows,
      percent,
    ],
  );

  const lineStringRegionsCollection = React.useMemo(() => {
    return regionCollection.features.filter((f) => f.geometry.type === 'LineString');
  }, [regionCollection]);

  const lineStringBorderRegions = React.useMemo(
    () => getRegionAsLineStringLayer(props.mapStyleMode),
    [props.mapStyleMode],
  );

  const regionLayers = React.useMemo(() => getRegionLayers(props.mapStyleMode), [
    props.mapStyleMode,
  ]);

  const centerLayers = React.useMemo(() => getCenterLayers(props.mapStyleMode), [
    props.mapStyleMode,
  ]);

  useLayers('line-string-border', lineStringBorderRegions, lineStringRegionsCollection);
  useLayers('regions', regionLayers, regionCollection);
  useLayers('centers', centerLayers, textFeatures);

  usePaintProperty('polygon-fill', 'fill-color', getFillExpression('fillColor'));
  usePaintProperty('polygon-outline', 'line-color', getFillExpression('lineColor'));

  const clickStatus = React.useRef({ clickedRegion: false });

  const handleMouseClick = React.useCallback((e: any) => {
    const feature = e.features[0];
    setPopup({ index: feature.properties.i, coords: e.lngLat });
    clickStatus.current.clickedRegion = true;
  }, []);

  useLayerEvent('click', 'polygon-fill', handleMouseClick);
  useLayerEvent('click', 'line-string-border', handleMouseClick);

  useMapEvent('click', () => {
    if (!clickStatus.current.clickedRegion) {
      setPopup(undefined);
    }
    clickStatus.current.clickedRegion = false;
  });

  const { map } = useMap();
  const internalHovered = React.useRef(-1);

  const handleMouseMove = React.useCallback(
    (e: any) => {
      const i = e.features[0].properties.i;
      if (i !== internalHovered.current) {
        (map as any)._canvas.style.cursor = 'pointer';
        internalHovered.current = i;
        props.onHover(i);
      }
    },
    [props.onHover],
  );

  useLayerEvent('mousemove', 'polygon-fill', handleMouseMove);
  useLayerEvent('mousemove', 'polygon-outline', handleMouseMove);

  const handleMouseLeave = React.useCallback(() => {
    if (internalHovered.current !== -1) {
      (map as any)._canvas.style.cursor = 'unset';
      internalHovered.current = -1;
      props.onHover(-1);
    }
  }, [props.onHover]);

  useLayerEvent('mouseleave', 'polygon-fill', handleMouseLeave);
  useLayerEvent('mouseleave', 'polygon-outline', handleMouseLeave);

  useFeatureState(
    'regions',
    props.hovered === -1 ? undefined : `${props.hovered}`,
    { hovered: true },
    { hovered: false },
  );
  useFeatureState(
    'centers',
    props.hovered === -1 ? undefined : `${props.hovered}`,
    { hovered: true },
    { hovered: false },
  );

  useLayoutProperty('polygon-name', 'visibility', props.labels ? 'visible' : 'none');

  const setFromId = React.useCallback(
    (index: number) => {
      if (props.setFromId) {
        props.setFromId(index);
        setPopup(undefined);
      }
    },
    [props.setFromId],
  );

  const setToId = React.useCallback(
    (index: number) => {
      if (props.setToId) {
        props.setToId(index);
        setPopup(undefined);
      }
    },
    [props.setToId],
  );

  const { min, max } = minMax(percent);

  const hideLegend = React.useMemo(
    () => props.hideLegend || (props.selectedFrom === -1 && props.selectedTo === -1),
    [props.hideLegend, props.selectedFrom, props.selectedTo],
  );

  const isOneToMany = React.useMemo(() => props.selectedFrom > -1 && props.selectedTo === -1, [
    props.selectedFrom,
    props.selectedTo,
  ]);

  const isManyToOne = React.useMemo(() => props.selectedFrom === -1 && props.selectedTo > -1, [
    props.selectedFrom,
    props.selectedTo,
  ]);

  const isVia = React.useMemo(() => props.selectedFrom > -1 && props.selectedTo > -1, [
    props.selectedFrom,
    props.selectedTo,
  ]);

  const fromName = React.useMemo(
    () => (props.selectedFrom > -1 ? regionName(props.regions, props.selectedFrom) : ''),
    [props.selectedFrom, props.regions],
  );

  const toName = React.useMemo(
    () => (props.selectedTo > -1 ? regionName(props.regions, props.selectedTo) : ''),
    [props.selectedTo, props.regions],
  );

  const popupName = React.useMemo(
    () => (popup !== undefined ? regionName(props.regions, popup.index) : ''),
    [popup, props.regions],
  );

  const popupPercent = React.useMemo(
    () =>
      popup !== undefined && percent !== undefined ? percent[popup.index].toFixed(2) : undefined,
    [popup, percent],
  );

  const popupRegionIsSelected = React.useMemo(
    () =>
      popup !== undefined &&
      (popup.index === props.selectedFrom || popup.index === props.selectedTo),
    [popup, props.selectedFrom, props.selectedTo],
  );

  return (
    <>
      {popup !== undefined && (
        <Popup position={popup.coords} offset={[0, -10]} key={JSON.stringify(popup.coords)}>
          <div className="MapFlows-popup">
            <div className="MapFlows-popup-title">{popupName}</div>
            {popupPercent && !popupRegionIsSelected && (
              <div className="MapFlows-popup-description">
                {isOneToMany && (
                  <>
                    <b>{popupPercent}%</b> of all trips that started in {fromName} ended in this
                    region
                  </>
                )}
                {isManyToOne && (
                  <>
                    <b>{popupPercent}%</b> of all trips that ended in {toName} started in this
                    region
                  </>
                )}
                {isVia && (
                  <>
                    <b>{popupPercent}%</b> of all trips that started in {fromName} and ended in{' '}
                    {toName} went through this region
                  </>
                )}
              </div>
            )}
            <div className="MapFlows-popup-actions">
              <Button $m="0.5sp 1sp" onClick={() => setFromId(popup.index)}>
                Set as origin
              </Button>
              <Button $m="0.5sp 1sp" onClick={() => setToId(popup.index)}>
                Set as destination
              </Button>
              {(popup.index === props.selectedTo || popup.index === props.selectedFrom) && (
                <Button
                  $m="0.5sp 1sp"
                  onClick={() => (popup.index === props.selectedTo ? setToId(-1) : setFromId(-1))}
                >
                  Clear
                </Button>
              )}
            </div>
          </div>
        </Popup>
      )}
      {}
      {!hideLegend && <ColorLegend min={min} max={max} rangeCount={rangeCount} />}
    </>
  );
};
