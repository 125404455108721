import React from 'react';
import classNames from 'classnames';
import { Text } from 'tombac';
import { ZoomToIcon } from 'tombac-icons';
import { useMap } from 'legoland-shared';
import { RegionDto } from 'model/RegionDto';
import { SimpleTooltip } from 'components/AnalysisTable/SimpleTooltip';
import { centerOnRegions } from 'components/Map/mapUtils';
import './ZoomInButton.css';

interface Props {
  position?:
    | 'default'
    | 'top-left'
    | 'top-right'
    | 'bottom-left'
    | 'bottom-right';
  type: 'regions' | 'link';
  regions?: RegionDto[];
}

const ZoomInButton: React.FC<Props> = ({
  position = 'default',
  type,
  regions,
}) => {
  const { map } = useMap();
  const text = React.useMemo(() => `Pan to ${type}`, [type]);

  const handleZoomInButtonClick = React.useCallback(() => {
    if (!regions || regions.length === 0) {
      return;
    }
    centerOnRegions(map, regions);
  }, [map, regions]);

  if (!regions || regions.length === 0) {
    return null;
  }

  return (
    <div
      className={classNames('ZoomInButton', {
        ['ZoomInButton--default']: position === 'default',
        ['ZoomInButton--topRight']: position === 'top-right',
        ['ZoomInButton--bottomRight']: position === 'bottom-right',
        ['ZoomInButton--bottomLeft']: position === 'bottom-left',
      })}
      onClick={handleZoomInButtonClick}
    >
      <SimpleTooltip
        content={
          <Text $color="#ffffff" style={{ width: 'max-content' }}>
            {text}
          </Text>
        }
        placement="auto"
      >
        <ZoomToIcon />
      </SimpleTooltip>
    </div>
  );
};

export default ZoomInButton;
