import React from 'react';
import { GlMapProps, GlMap as LegolandGlMap } from 'legoland-shared';
import { useMapStyleSettings } from 'reducers/mapStyleSettingsReducer';
import './GlMap.css';

export const apiKey = '1ncwaIygtJ0KrjH5ssohlEKUGFf7G5Dv';

const GlMap: React.FC<
  Omit<GlMapProps, 'apiKey' | 'mapStyleSettings' | 'onMapStyleSettingsChange'>
> = (props) => {
  const [mapStyleSettings, setMapStyleSettings] = useMapStyleSettings();

  return (
    <LegolandGlMap
      apiKey={apiKey}
      mapStyleSettings={mapStyleSettings}
      onMapStyleSettingsChange={setMapStyleSettings}
      {...props}
    />
  );
};

export default GlMap;
