import { MapStyleMode } from 'legoland-shared';

type Colors = { [key in MapStyleMode]: string };

interface LayerPaint {
  default: {
    fillColor: Colors;
    lineColor: Colors;
    textColor: Colors;
    textHaloColor: Colors;
  };
  hover: {
    fillColor: Colors;
    lineColor: Colors;
    textColor: Colors;
    textHaloColor: Colors;
  };
  selected: {
    fillColor: Colors;
    lineColor: Colors;
    textColor: Colors;
    textHaloColor: Colors;
  };
}

/**
 * Gets a color with a given opacity in rgba(r, g, b, o) format.
 * Numbers outside of range will fallback to closest correct min-max value. RGB Decimals are rounded to the nearest integer.
 * @param r Red 0-255
 * @param g Green 0-255
 * @param b Blue 0-255
 * @param o Opacity 0-1
 * @returns Color in string format rgba(r, g, b, o).
 */
export const getRgba = (r: number, g: number, b: number, o: number) => {
  const validateColor = (n: number) =>
    Math.min(Math.max(Math.round(n), 0), 255);
  const opacity = Math.min(Math.max(o, 0), 1);

  return `rgba(${validateColor(r)}, ${validateColor(g)}, ${validateColor(
    b,
  )}, ${opacity})`;
};

/**
 * Color properties when drawing and displaying custom layers.
 */
export const COLORS = {
  WARNING_FILL: '#f0ad4e',
  WARNING_OUTLINE: '#a9742a',
  ERROR: '#df1b12',
  DARK_GREY_444: '#444',
  DARK_GREY_333: '#333',
  TRANSPARENT: 'rgba(0,0,0,0)',
};

/**
 * Functions which get predefined colors with a given opacity in rgba(r, g, b, o) format.
 * @param opacity defaults to 1.
 */
export const RGBA = {
  lighterBlue: (opacity = 1) => getRgba(107, 202, 242, opacity), // #6bcaf2
  lightBlue: (opacity = 1) => getRgba(97, 173, 224, opacity), // #61ade0
  blue: (opacity = 1) => getRgba(42, 144, 213, opacity), // #2a90d5
  brandBlue: (opacity = 1) => getRgba(0, 170, 255, opacity), // #00aaff
  blueGrey: (opacity = 1) => getRgba(150, 150, 180, opacity), // #9696b4
  aquaGrey: (opacity = 1) => getRgba(148, 176, 197, opacity), // #94b0c5
  yellow: (opacity = 1) => getRgba(253, 197, 48, opacity), // #fdc530:
  white: (opacity = 1) => getRgba(255, 255, 255, opacity), // #ffffff
  black: (opacity = 1) => getRgba(0, 0, 0, opacity), // #000000
  grey: (opacity = 1) => getRgba(136, 136, 136, opacity), // #888888
  darkGrey333: (opacity = 1) => getRgba(51, 51, 51, opacity), // #333333
  darkGrey444: (opacity = 1) => getRgba(68, 68, 68, opacity), // #333333
};

const regionsLayerPaint: LayerPaint = {
  default: {
    fillColor: {
      light: RGBA.grey(1),
      dark: RGBA.yellow(1),
      satellite: RGBA.white(1),
    },
    lineColor: {
      light: RGBA.grey(1),
      dark: RGBA.yellow(1),
      satellite: RGBA.white(1),
    },
    textColor: {
      light: RGBA.black(0.7),
      dark: RGBA.white(0.7),
      satellite: RGBA.white(0.9),
    },
    textHaloColor: {
      light: RGBA.white(0.5),
      dark: RGBA.black(0.5),
      satellite: RGBA.black(0.5),
    },
  },
  hover: {
    fillColor: {
      light: RGBA.lightBlue(1),
      dark: RGBA.white(1),
      satellite: RGBA.white(1),
    },
    lineColor: {
      light: RGBA.lightBlue(1),
      dark: RGBA.lightBlue(1),
      satellite: RGBA.brandBlue(1),
    },
    textColor: {
      light: RGBA.white(0.9),
      dark: RGBA.white(0.9),
      satellite: RGBA.white(0.9),
    },
    textHaloColor: {
      light: RGBA.blue(0.7),
      dark: RGBA.blue(0.7),
      satellite: RGBA.brandBlue(0.7),
    },
  },
  selected: {
    fillColor: {
      light: RGBA.lightBlue(0.3),
      dark: RGBA.yellow(0.3),
      satellite: RGBA.yellow(0.3),
    },
    lineColor: {
      light: RGBA.lightBlue(1),
      dark: RGBA.yellow(1),
      satellite: RGBA.yellow(1),
    },
    textColor: {
      light: RGBA.lighterBlue(1),
      dark: RGBA.yellow(1),
      satellite: RGBA.white(1),
    },
    textHaloColor: {
      light: RGBA.black(0.5),
      dark: RGBA.white(0.9),
      satellite: RGBA.lightBlue(1),
    },
  },
};

/**
 * Colors per state and per map style mode for custom layers.
 */
export const LAYER_PAINT: {
  [key in 'regions' | 'mapFlows']: LayerPaint;
} = {
  regions: regionsLayerPaint,
  mapFlows: {
    ...regionsLayerPaint,
    default: {
      ...regionsLayerPaint.default,
      fillColor: {
        light: RGBA.grey(0.2),
        dark: RGBA.yellow(0.2),
        satellite: RGBA.white(0.2),
      },
      lineColor: {
        light: RGBA.grey(0.7),
        dark: RGBA.yellow(0.7),
        satellite: RGBA.white(0.7),
      },
    },
    hover: {
      ...regionsLayerPaint.hover,
      fillColor: {
        light: RGBA.lightBlue(0.2),
        dark: RGBA.yellow(0.2),
        satellite: RGBA.white(0.2),
      },
      lineColor: {
        light: RGBA.lightBlue(1),
        dark: RGBA.brandBlue(1),
        satellite: RGBA.brandBlue(1),
      },
    },
  },
};
