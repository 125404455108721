/* eslint-disable react-hooks/exhaustive-deps */
import chroma from 'chroma-js';
import clamp from 'lodash/clamp';
import cloneDeep from 'lodash/cloneDeep';
import 'rc-slider/assets/index.css';
import {
  Dispatch,
  memo,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import {
  Box,
  Button,
  FormGroup,
  Input,
  RadioGroup,
  Select,
  Text,
  tombac,
  TooltipOnHover,
  useTombac,
} from 'tombac';
import { CancelIcon, DeleteIcon, SettingsIcon, UndoIcon } from 'tombac-icons';
import { ArrowsIcon } from './ArrowsIcon';
import { rgba } from './color';
import { countSteps, Palette, PaletteScale } from './ColorPalette';
import { defaultColorPalettes } from './defaultColorPalettes';
import {
  ColorBoxPicker,
  DiscretePicker,
  DiscretePreview,
} from './DiscretePicker';
import { GradientPicker, GradientPreview } from './GradientPicker';
import { ScalePicker } from './ScalePicker';
import { useFrequentValue } from './useFrequentValue';

const Radio = memo(RadioGroup);

const PickerContainer = styled.div`
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px ${tombac.color('neutral', 400)};
  position: absolute;
  bottom: 0;
  z-index: 10;
  margin: ${tombac.space(3)};
  margin-bottom: ${tombac.space(5)};
  width: 510px;
`;

const PickerHeader = styled.div`
  background: #fff;
  padding: 14px 16px;
  border-bottom: 1px solid #e5e5e5;
`;

const PickerContent = styled.div`
  position: relative;
  background-color: ${tombac.color('neutral', 200)};
  padding: 16px 24px;
`;

const PickerActions = styled.div`
  display: flex;
  gap: ${tombac.space(0.5)};
  margin: 10px;
  position: absolute;
  right: 12px;
  top: 40px;
`;

interface PalettePreviewProps {
  palette: Palette;
  onDelete?: () => void;
}

const PalettePreview: React.FC<PalettePreviewProps> = ({
  palette,
  onDelete,
}) => {
  const [hovered, setHovered] = useState(false);

  return (
    <Box
      $display="flex"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Box $width="130px" $height="16px" $mr="1sp" $display="flex">
        <div
          style={{
            background: palette.noDataColor,
            width: '6px',
          }}
        ></div>
        {palette.isDiscrete ? (
          <DiscretePreview palette={palette} />
        ) : (
          <GradientPreview stops={palette.stops} />
        )}
      </Box>
      {palette.name}
      {onDelete !== undefined && hovered && (
        <Button
          $height="16u"
          $minWidth="16u"
          $width="16u"
          $ml="auto"
          shape="circle"
          variant="flat"
        >
          <DeleteIcon width="5px" size="xs" />
        </Button>
      )}
    </Box>
  );
};

interface ExcludeColorPickerProps {
  color: string;
  onChange: (color: string) => void;
  label: string;
}

const ExcludeColorPicker: React.FC<ExcludeColorPickerProps> = ({
  color,
  onChange,
  label,
}) => {
  return (
    <Box
      $display="flex"
      $flexDirection="row"
      $alignItems="center"
      $mr="1sp"
      $mb="0.5sp"
    >
      <ColorBoxPicker
        width="20px"
        color={color}
        onChange={(newColorString) => {
          // When user picks a new color and the alpha channel is
          // at zero - make it non transparent so the user is not confused
          let newColor = chroma(newColorString);
          let oldColor = chroma(color);
          if (
            newColor.alpha() === 0 &&
            newColor.hex('rgb') !== oldColor.hex('rgb')
          ) {
            newColor = newColor.alpha(1);
          }
          onChange(rgba(newColor));
        }}
      />
      <Text $ml="0.5sp" fontSize={12}>
        {label}
      </Text>
    </Box>
  );
};

interface ColorPalettePickerInternalProps {
  unitLabel: string;
  palette: Palette;
  scale: PaletteScale;
  onPaletteChange: Dispatch<SetStateAction<Palette>>;
  onScaleChange: Dispatch<SetStateAction<PaletteScale>>;
}

const ColorPalettePickerInternal: React.FC<ColorPalettePickerInternalProps> = ({
  unitLabel,
  palette,
  scale: defaultScale,
  onPaletteChange,
  onScaleChange,
}) => {
  const tombac = useTombac();
  const [expanded, setExpanded] = useState(false);
  const [scale, setScale] = useFrequentValue<PaletteScale>(
    defaultScale,
    { debounce: 50 },
    onScaleChange,
  );
  const [selectedPalette, setSelectedPalette] = useState<Palette>(palette);
  const palettes = defaultColorPalettes;
  const stepInput = useRef<HTMLInputElement>(null);

  const { min, max } = scale;

  const alignScale = (step: number = scale.step) => {
    const findClosest = (value: number, step: number): number => {
      if (value === min || value === max) return value;
      return clamp(Math.round((value - min) / step) * step + min, min, max);
    };

    const newFrom = findClosest(scale.from, step);
    const newTo = findClosest(scale.to, step);

    if (newFrom !== scale.from || newTo !== scale.to || step !== scale.step) {
      setScale({
        ...scale,
        from: newFrom,
        to: newTo,
        step,
      });
    }
  };

  const onStepChange = (e: any) => {
    const minStep = 1;
    const maxStep = max - min;

    const parsedValue = Number(e.target.value);
    const newStep = isNaN(parsedValue)
      ? scale.step
      : clamp(parsedValue, minStep, maxStep);

    alignScale(newStep);
  };

  const reverseColors = () => {
    const { stops, customDiscreteColors } = palette;
    const newStops = stops.map((it) => ({ ...it, offset: 1 - it.offset }));
    const count = countSteps(scale) - 1;
    const newCustomDiscreteColors = customDiscreteColors?.map((it) => ({
      ...it,
      index: count - it.index,
    }));

    onPaletteChange({
      ...palette,
      stops: newStops,
      customDiscreteColors: newCustomDiscreteColors,
    });
  };

  const resetChanges = () => {
    onScaleChange({ ...scale, from: scale.min, to: scale.max });
    if (selectedPalette) {
      setSelectedPalette((prev) => ({
        ...prev,
        customDiscreteColors: undefined,
      }));
      onPaletteChange({ ...selectedPalette });
    }
  };

  const canRestore = useMemo(
    () =>
      scale.min !== scale.from ||
      scale.max !== scale.to ||
      palette.customDiscreteColors ||
      (selectedPalette !== undefined &&
        JSON.stringify(palette) !== JSON.stringify(selectedPalette)),
    [palette, selectedPalette],
  );

  useEffect(() => {
    if (palette.isDiscrete) {
      alignScale();
    }
  }, [palette.isDiscrete]);

  useEffect(() => {
    if (stepInput.current) {
      stepInput.current.value = String(scale.step);
    }
  }, [scale.step]);

  const [scaleOptions] = useState(() => [
    { label: 'Discrete', value: true },
    { label: 'Continuous', value: false },
  ]);

  const selectOptions = useMemo(
    () => palettes.map((it, i) => ({ value: i, label: it.name })),
    [palettes],
  );

  const selectPalette = (palette: Palette) => {
    const newPalette = cloneDeep(palette);
    setSelectedPalette(newPalette);
    onPaletteChange(newPalette);
  };

  const onDelete = (id: string) => {
    if (id === selectedPalette?.id) {
      selectPalette(palettes[0]);
    }
  };

  useEffect(() => {
    if (!Palette.equals(selectedPalette, palette)) {
      setSelectedPalette(palette);
    }
  }, [selectedPalette, palette]);

  const editingPaletteOption = useMemo(() => {
    return selectOptions.find(({ value: index }) => {
      const it = palettes[index];
      return Palette.equals(selectedPalette, it);
    });
  }, [palettes, selectedPalette]);

  if (!expanded) {
    return (
      <PickerContainer>
        <PickerContent
          style={{ display: 'flex', padding: '13px 28px 13px 15px' }}
        >
          <Button $mr="3sp" onClick={() => setExpanded(true)} shape="circle">
            <SettingsIcon />
          </Button>
          {palette.isDiscrete ? (
            <DiscretePicker
              scale={scale}
              palette={palette}
              unitLabel={unitLabel}
            />
          ) : (
            <GradientPicker
              scale={scale}
              palette={palette}
              unitLabel={unitLabel}
            />
          )}
        </PickerContent>
      </PickerContainer>
    );
  }

  return (
    <PickerContainer>
      <PickerHeader>
        <Button
          $position="absolute"
          $right="0.5sp"
          $top="0.5sp"
          shape="circle"
          size="s"
          onClick={() => setExpanded(false)}
          variant="flat"
        >
          <CancelIcon />
        </Button>
        <Text altFont fontSize={14} fontWeight="bold" $mb="1sp">
          Customize scale
        </Text>
        <Box $display="flex" $flexDirection="row" $width="100%">
          <Select
            $flex="1"
            menuPlacement="top"
            options={selectOptions}
            value={editingPaletteOption}
            formatOptionLabel={({ value }, { context }) => (
              <PalettePreview
                palette={palettes[value]}
                onDelete={
                  context === 'menu' && palettes[value].id !== undefined
                    ? () => {
                        if (palettes[value]?.id) {
                          onDelete(palettes[value].id);
                        }
                      }
                    : undefined
                }
              />
            )}
            onChange={(e) => {
              selectPalette(palettes[(e as any).value]);
            }}
          />
        </Box>
      </PickerHeader>
      <PickerContent>
        <PickerActions>
          <TooltipOnHover variant="inverted" content="Reverse" placement="top">
            <Button
              onClick={reverseColors}
              shape="circle"
              size="s"
              variant="flat"
            >
              <ArrowsIcon
                style={{ display: 'block', transform: 'rotate(90deg)' }}
              />
            </Button>
          </TooltipOnHover>
          {canRestore ? (
            <TooltipOnHover variant="inverted" content="Reset" placement="top">
              <Button
                onClick={resetChanges}
                shape="circle"
                size="s"
                variant="flat"
              >
                <UndoIcon />
              </Button>
            </TooltipOnHover>
          ) : (
            <Button
              disabled={!canRestore}
              shape="circle"
              size="s"
              variant="flat"
            >
              <UndoIcon />
            </Button>
          )}
        </PickerActions>
        <FormGroup label="Scale type">
          <Radio
            variant="horizontal"
            value={scaleOptions.find((it) => it.value === palette.isDiscrete)}
            options={scaleOptions}
            onChange={(e: any) =>
              onPaletteChange({ ...palette, isDiscrete: e.value })
            }
          />
        </FormGroup>

        <Box $mt="2sp" $mb="2sp">
          {palette.isDiscrete ? (
            <DiscretePicker
              scale={scale}
              palette={palette}
              onChange={onPaletteChange}
            />
          ) : (
            <GradientPicker
              scale={scale}
              palette={palette}
              onChange={onPaletteChange}
            />
          )}
        </Box>

        <Box
          $display="flex"
          style={{ borderTop: '1px solid #e5e5e5' }}
          $pt="3sp"
          $mt="2sp"
        >
          <Box $width="350px">
            <FormGroup
              $mb="2sp"
              layout="inline"
              label="Scale range"
              $width="350px"
            >
              <ScalePicker
                isDiscrete={palette.isDiscrete}
                scale={scale}
                onChange={setScale}
                unitLabel={unitLabel}
              />
            </FormGroup>
            {palette.isDiscrete && (
              <FormGroup layout="inline" label="Steps every" $mt="2sp">
                <Box $display="flex" $alignItems="center">
                  <Input
                    $height="32px"
                    placeholder="step"
                    defaultValue={scale.step}
                    onBlur={onStepChange}
                    onKeyPress={(e) => e.key === 'Enter' && onStepChange(e)}
                    ref={stepInput}
                  />
                  <Text
                    $color={tombac.color('neutral', 600)}
                    fontSize={14}
                    $ml="1sp"
                  >
                    {unitLabel}
                  </Text>
                </Box>
              </FormGroup>
            )}
          </Box>

          {(scale.from !== scale.min || scale.to !== scale.max) && (
            <Box>
              <Text fontSize={12} $mt="-12u">
                Excluded
              </Text>
              <Box $display="flex" style={{ flexWrap: 'wrap' }}>
                {scale.from !== scale.min && (
                  <ExcludeColorPicker
                    color={palette.excludeBelowColor}
                    onChange={(excludeBelowColor) =>
                      onPaletteChange({ ...palette, excludeBelowColor })
                    }
                    label={`< ${scale.from}`}
                  />
                )}
                {scale.to !== scale.max && (
                  <ExcludeColorPicker
                    color={palette.excludeAboveColor}
                    onChange={(excludeAboveColor) =>
                      onPaletteChange({ ...palette, excludeAboveColor })
                    }
                    label={`> ${scale.to}`}
                  />
                )}
              </Box>
            </Box>
          )}
        </Box>
      </PickerContent>
    </PickerContainer>
  );
};

export const ColorPalettePicker = memo(ColorPalettePickerInternal);
