import { useEffect } from 'react';
import { MapLayerEventType } from 'mapbox-gl';
import { useMap } from 'legoland-shared';

export const useLayerEvent = (
  type: keyof MapLayerEventType,
  layerId: string,
  fn: any,
) => {
  const { map } = useMap();

  useEffect(() => {
    map.on(type, layerId, fn);

    return () => {
      map.off(type, layerId, fn);
    };
  }, [layerId, type, fn]);
};
