import { useEffect } from 'react';
import { useMap } from 'legoland-shared';

export const useImageUrl = (name: string, url: string) => {
  const { map } = useMap();
  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      if (map.hasImage(name)) return;
      map.addImage(name, img);
    };
    img.src = url;

    return () => {
      if (map.hasImage(name)) map.removeImage(name);
    };
  }, [name, url]);
};

export const useSvgImage = (name: string, svg: string) => {
  const src = `data:image/svg+xml;base64,${window.btoa(svg)}`;

  return useImageUrl(name, src);
};
