import Popup from 'components/Map/Layers/Popup';
import { GreyText } from 'components/UI/Text.style';
import styled from 'styled-components';
import { Box, HFlex, tombac, TooltipOnHover, Text } from 'tombac';
import { InfoIcon } from 'tombac-icons';
import './TrimsInfoPopup.css';
import { SelectedSegmentConnection } from './SelectedLinkTree';

const TooltipContentText = styled(Text)`
  font-family: Proxima Nova;
  font-size: 12px;
  color: ${tombac.color('neutral', 800)};
`;

const GrayInfoIcon = styled(InfoIcon)`
  color: ${tombac.color('neutral', 700)};
`;

interface Props {
  selectedSegmentConnection: SelectedSegmentConnection;
  onClose: VoidFunction;
}

export function TrimsInfoPopup({ selectedSegmentConnection, onClose }: Props) {
  const coords = selectedSegmentConnection.feature?.geometry.coordinates;
  const privacyTrims =
    selectedSegmentConnection?.feature?.properties?.['privacy-trims'];
  const processingFailures =
    selectedSegmentConnection?.feature?.properties?.['processing-failures'];
  const tripsLost =
    selectedSegmentConnection?.feature?.properties?.['trips-lost'];

  return (
    <Popup
      position={coords}
      offset={[0, -15]}
      maxWidth={500}
      className="TrimsInfoPopup"
      onClose={onClose}
    >
      <Box>
        <HFlex $alignItems="center">
          <GreyText>
            {privacyTrims} trips trimmed due to privacy rules{' '}
          </GreyText>
          <TooltipOnHover
            size="s"
            placement="top"
            content={
              <TooltipContentText>
                This value indicates trips affected by the additional
                anonymization measures implemented by TomTom, such as trimming
                parts of trips that ended on FRC 7 roads.
              </TooltipContentText>
            }
          >
            <GrayInfoIcon size={'sm'} />
          </TooltipOnHover>
        </HFlex>
        <HFlex $alignItems="center">
          <GreyText>{processingFailures} trip processing errors </GreyText>
          <TooltipOnHover
            size="s"
            placement="top"
            content={
              <TooltipContentText>
                This value shows trips that could not be accurately matched to
                the map beyond this point, often due to poor GPS quality or
                sudden jumps in location.
              </TooltipContentText>
            }
          >
            <GrayInfoIcon size={'sm'} />
          </TooltipOnHover>
        </HFlex>
        <HFlex $alignItems="center">
          <GreyText>{tripsLost} trips that ended at this location </GreyText>
          <TooltipOnHover
            size="s"
            placement="top"
            content={
              <TooltipContentText>
                This value reflects trips that finished here. Unrealistic
                endpoints, like mid-highway stops, often result from data
                fragmentation by providers or GPS signal loss, such as when a
                device is turned off or runs out of battery.
              </TooltipContentText>
            }
          >
            <GrayInfoIcon size={'sm'} />
          </TooltipOnHover>
        </HFlex>
      </Box>
    </Popup>
  );
}
