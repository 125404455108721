import { useEffect } from 'react';
import bbox from '@turf/bbox';
import { FeatureCollection, Feature } from '@turf/helpers';
import { useMap } from 'legoland-shared';

export const useMapCenterEffect = (
  regions: Feature | FeatureCollection | undefined,
  animate = true,
  padding = 100,
) => {
  const { map } = useMap();

  useEffect(() => {
    if (regions === undefined) {
      return;
    }
    const regionsBbox = bbox(regions) as any;
    map.fitBounds(regionsBbox, { padding, animate, duration: 500 });
  }, [regions, animate, padding]);
};
