import { useEffect, useMemo } from 'react';
import bezierSpline from '@turf/bezier-spline';
import centroid from '@turf/centroid';
import { Feature, featureCollection, lineString, Point } from '@turf/helpers';
import { useMap } from 'legoland-shared';
import { useLayerEvent } from 'hooks/useLayerEvent';
import useLayers from 'hooks/useLayers';
import { clamp } from 'logic/math';
import { RegionDto } from 'model/RegionDto';
import { heatMapColor } from '../MapFlows/colors';
import { colorTextScale } from '../MapFlows/colorScale';
import { isLayerLineString } from 'components/Map/mapUtils';
import { RGBA } from 'model/Colors';

const compareByFlows = (a: any, b: any) =>
  a.properties.flow - b.properties.flow;

export interface Strand {
  source: number;
  target: number;
  flow: number;
}

interface Props {
  flows: Strand[];
  dtoRegions: Array<Readonly<RegionDto>>;
  regionNames: boolean;
  filter: number;
  period: string;
  carsPerKm: boolean;
  selectedStrand?: Strand | undefined;
  onStrandSelect?: (strand: Strand | undefined) => void;
}

const regionLayers: mapboxgl.Layer[] = [
  {
    id: 'ssankey-region-outline',
    type: 'line',
    layout: {
      'line-cap': 'round',
    } as any,
    paint: {
      'line-color': [
        'case',
        isLayerLineString,
        RGBA.black(0.5),
        RGBA.black(0.2),
      ],
      'line-width': ['case', isLayerLineString, 7, 1.5],
    },
  },
  {
    id: 'ssankey-region-fill',
    type: 'fill',
    paint: {
      'fill-color': 'rgba(0,0,0,0.02)',
    },
  },
];

const linesLayers: mapboxgl.Layer[] = [
  {
    id: 'ssankey-lines',
    type: 'line',
    layout: {
      'line-cap': 'round',
    } as any,
    paint: {
      'line-width': {
        base: 1.2,
        stops: [
          [12, 2.5],
          [20, 30],
        ],
      },
    },
  },
];

const namesLayers: mapboxgl.Layer[] = [
  {
    id: 'polygon-name',
    type: 'symbol',
    layout: {
      'text-field': '{text}',
      'text-font': ['Noto-Regular'],
      'text-offset': [0, 0.1],
      'text-size': ['get', 'text-size'],
    } as any,
    paint: {
      'text-color': ['get', 'text-color'],
      'text-halo-color': RGBA.white(0.7),
      'text-halo-width': 2,
    },
  },
];

const linesHightlightLayers: mapboxgl.Layer[] = [
  {
    id: 'ssankey-highlight-lines',
    type: 'line',
    layout: {
      'line-cap': 'round',
    } as any,
    paint: {
      'line-color': '#61ade0',
      'line-width': {
        base: 1.2,
        stops: [
          [12, 6.5],
          [20, 50],
        ],
      },
    },
  },
];

const makeLayerData = (
  flows: Strand[],
  centers: Array<
    Feature<
      Point,
      {
        [name: string]: any;
      }
    >
  >,
) => {
  const minMax = { min: 0, max: -Infinity };
  const regionToFlowsSum = new Array(centers.length).fill(0);
  {
    let i = 0;
    const length = flows.length;
    for (i = 0; i < length; i++) {
      const el = flows[i];
      regionToFlowsSum[el.source] += el.flow;
      regionToFlowsSum[el.target] += el.flow;
      if (el.flow < minMax.min) {
        minMax.min = el.flow;
      }
      if (el.flow > minMax.max) {
        minMax.max = el.flow;
      }
    }
  }
  const maxRegionFlowSum = Math.max(...regionToFlowsSum);

  const makeCurve = (from: number, to: number) => {
    const sx = 0.4;
    const sy = 0.5;
    const source = centers[from].geometry.coordinates;
    const target = centers[to].geometry.coordinates;
    const dx = source[0] - target[0];
    const dy = source[1] - target[1];
    const controls = [sx * dx, sy * dy];
    return [source, [source[0] - controls[0], source[1] - controls[1]], target];
  };

  const lines = flows.map((flow, id) =>
    bezierSpline(lineString(makeCurve(flow.source, flow.target)), {
      resolution: 700,
      properties: {
        flow: flow.flow,
        source: flow.source,
        target: flow.target,
        id,
      },
    }),
  );
  const linesCollection = featureCollection([...lines].sort(compareByFlows));

  const names = centers.map((f, i) => {
    const percent = regionToFlowsSum[i] / maxRegionFlowSum;
    const value = isNaN(percent) ? 0 : percent * 100;
    const log = value === 0 ? 0 : (100 * Math.log(value)) / Math.log(100);
    const safeLog = clamp(0, 100, log);

    return {
      ...f,
      properties: {
        ...f.properties,
        'text-size': 8 + 7 * (safeLog / 100),
        'text-color': colorTextScale(safeLog - 20),
      },
    };
  });
  const namesCollection = featureCollection(names);

  return { linesCollection, namesCollection, linesMinMax: minMax };
};

function SpatialSankeyGl(props: Props): any {
  const regionCenters = useMemo(
    () =>
      props.dtoRegions.map((region) =>
        centroid(region, { properties: { text: region.properties.name } }),
      ),
    [props.dtoRegions],
  );

  const { linesCollection, namesCollection, linesMinMax } = useMemo(
    () => makeLayerData(props.flows, regionCenters),
    [props.flows, regionCenters],
  );
  const regionCollection = useMemo(() => featureCollection(props.dtoRegions), [
    props.dtoRegions,
  ]);

  const linesHightlightCollection = useMemo(() => {
    if (!props.selectedStrand) {
      return undefined;
    }
    const { source, target } = props.selectedStrand;
    const line = linesCollection.features.find(
      (f) =>
        (f.properties.source === source && f.properties.target === target) ||
        (f.properties.source === target && f.properties.target === source),
    );
    return featureCollection([line]);
  }, [props.selectedStrand]);

  useLayers('ssankey-region', regionLayers, regionCollection);
  useLayers(
    'ssankey-highlight',
    linesHightlightLayers,
    linesHightlightCollection,
  );
  useLayers('ssankey-flows', linesLayers, linesCollection);
  useLayers('ssankey-names', namesLayers, namesCollection);

  useLayerEvent('click', linesLayers[0].id, (e: any) => {
    const strand = { ...e.features[0].properties };
    props.onStrandSelect(strand);
  });

  const { map: mapbox } = useMap();

  const linesId = linesLayers[0].id;

  useEffect(() => {
    if (linesMinMax.max === -Infinity) return;
    mapbox.setPaintProperty(linesId, 'line-opacity', {
      property: 'flow',
      type: 'exponential',
      stops: [
        [linesMinMax.min, 0.45],
        [linesMinMax.max, 1],
      ],
    });
    mapbox.setPaintProperty(linesId, 'line-color', {
      property: 'flow',
      type: 'exponential',
      stops: [
        [linesMinMax.min, heatMapColor(10).css()],
        [linesMinMax.max, heatMapColor(100).css()],
      ],
    });
  }, [linesCollection, linesMinMax]);

  // Update lower filter
  useEffect(() => {
    mapbox.setFilter(linesId, [
      '>=',
      'flow',
      linesMinMax.max * (props.filter / 100),
    ]);
  }, [props.filter, linesMinMax.max]);

  // Hide/show region names
  useEffect(() => {
    mapbox.setLayoutProperty(
      'polygon-name',
      'visibility',
      props.regionNames ? 'visible' : 'none',
    );
  }, [props.regionNames]);

  // Handle strands click
  useEffect(() => {
    const listener = (e: any) => {
      const bbox = [
        [e.point.x - 10, e.point.y - 10],
        [e.point.x + 10, e.point.y + 10],
      ];
      const features = mapbox.queryRenderedFeatures(bbox as any, {
        layers: [linesId],
      });
      if (features.length === 0) {
        props.onStrandSelect(undefined);
        return;
      }

      const id = features[0].properties.id;
      const strand = props.flows[id];

      props.onStrandSelect(strand);
    };
    mapbox.on('click', listener);
    return () => {
      mapbox.off('click', listener);
    };
  }, [mapbox, props.flows]);

  return null;
}

export default SpatialSankeyGl;
