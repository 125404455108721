import 'babel-polyfill';
import { enableMapSet } from 'immer';
import { LegolandApp } from 'legoland-sdk/dist/experimental';
import { AnchorHTMLAttributes, forwardRef, Ref } from 'react';
import { createRoot } from 'react-dom/client';
import Modal from 'react-modal';
import { Provider } from 'react-redux';
import { Link, Router } from 'react-router-dom';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { TombacApp } from 'tombac';
import 'tombac-icons/react/style.css';
import './app.css';
import { BasePage } from './components/Layout/BasePage';
import { DebugTools } from './components/DebugTools/DebugTools';
import { appConfig } from './logic/appConfig';
import { browserHistory, initTracking } from './logic/tracking';
import combinedReducers from './reducers/combinedReducer';
import { Buffer } from 'buffer';

window.Buffer = window.Buffer || Buffer;

Modal.setAppElement('#root');

enableMapSet();

initTracking();

const composeEnhancers = composeWithDevTools({
  stateSanitizer: (state: any) => ({
    ...state,
    analysis: {
      ...state.analysis,
      result: '<<DATA>>',
    },
  }),
  actionSanitizer: (action: any) => {
    if (action.type === 'RECEIVE_ANALYSIS') {
      // eslint-disable-next-line
      const { result, ...other } = action.analysis;
      return {
        ...action,
        analysis: { ...other },
      };
    }

    return action;
  },
});
const store = createStore(
  combinedReducers,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  composeEnhancers(applyMiddleware(thunk)),
);

export function getStore() {
  return store.getState();
}

export function getReduxStore() {
  return store;
}

const InternalLink = forwardRef(function InternalLink(
  { href, ...rest }: AnchorHTMLAttributes<HTMLAnchorElement>,
  ref: Ref<HTMLAnchorElement>,
) {
  return <Link to={href} {...rest} ref={ref} />;
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <TombacApp
    theme={{ baseUnit: 'px', settings: { modalZIndex: 2000 } }}
    defineCssVariables
  >
    <Router history={browserHistory}>
      <LegolandApp
        environment={appConfig.stage === 'prod' ? 'production' : 'development'}
        internalLinkComponent={InternalLink}
        productId="od"
        releaseNotesUrl="/release-notes"
      >
        <Provider store={store as any}>
          <BasePage />
          <DebugTools />
        </Provider>
      </LegolandApp>
    </Router>
  </TombacApp>,
);
