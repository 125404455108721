import chroma from 'chroma-js';
import clamp from 'lodash/clamp';
import deepEqual from 'lodash/isEqual';
import { rgba } from './color';
import { RGBA } from 'model/Colors';

export interface ColorStop {
  offset: number;
  color: string;
  id: number;
}

const ColorStop = {
  make: (color: string, offset: number) =>
    ({
      offset,
      color: rgba(chroma(color)),
      id: Math.random(),
    } as ColorStop),
};

export interface PaletteScale {
  step: number;
  from: number;
  to: number;
  readonly min: number;
  readonly max: number;
}

export const PaletteScale = {
  makeMinMax: (min: number, max: number, stepsCount = 13): PaletteScale => {
    const step = Math.max(1, Math.floor((max - min) / stepsCount));

    return {
      step,
      from: Math.ceil(min),
      to: Math.ceil(max),
      min: Math.ceil(min),
      max: Math.ceil(max),
    };
  },
};

export interface CustomDiscreteColor {
  index: number;
  color: string;
}

export interface Palette {
  id?: string;
  name: string;
  stops: ColorStop[];
  isDiscrete: boolean;
  noDataColor: string;
  excludeBelowColor: string;
  excludeAboveColor: string;
  customDiscreteColors?: CustomDiscreteColor[];
  _cache?: {
    id: string;
    scale: chroma.Scale<chroma.Color>;
  };
}

export const Palette = {
  make(
    name: string,
    colors: Array<string | ColorStop>,
    rest?: Partial<Omit<Palette, 'name' | 'stops'>>,
  ): Palette {
    const stops = colors.map((it: string | ColorStop, i) =>
      typeof it === 'string'
        ? ColorStop.make(it, i / (colors.length - 1))
        : ColorStop.make(it.color, it.offset),
    );
    return {
      name,
      stops,
      noDataColor: rgba(RGBA.grey(1)),
      excludeAboveColor: rgba(RGBA.black(0)),
      excludeBelowColor: rgba(RGBA.black(0)),
      isDiscrete: false,
      ...rest,
    };
  },
  getColor(palette: Palette, scale: PaletteScale, value: number): string {
    const { from, to, min, max } = scale;

    value = clamp(value, min, max);

    if (value > to) return palette.excludeAboveColor;
    if (value < from) return palette.excludeBelowColor;

    const chromaScale = Palette.getChromaScale(palette, scale.from, scale.to);

    if (palette.isDiscrete) {
      const count = countSteps(scale);
      const discreteValue = isLastStep(count, scale.step, value)
        ? scale.to
        : from + (value - from) - ((value - from) % scale.step);

      if (!palette.customDiscreteColors) {
        return rgba(chromaScale(discreteValue));
      }

      const customDiscreteColor = getCustomDiscreteColor(
        scale,
        discreteValue,
        count,
        palette.customDiscreteColors,
      );

      return customDiscreteColor ?? rgba(chromaScale(discreteValue));
    }

    return rgba(chromaScale(value));
  },
  getDiscreteColors(
    palette: Palette,
    from: number,
    to: number,
    step: number,
  ): string[] {
    const chromaScale = Palette.getChromaScale(palette, from, to);
    const colors = [];

    const count = Math.ceil((to - from) / step);

    for (let i = 0; i < count; i++) {
      const stepValue = from + i * step;
      const stepColor =
        palette.customDiscreteColors?.find((it) => it.index === i)?.color ??
        rgba(chromaScale(i == count - 1 ? to : stepValue));

      colors.push(stepColor);
    }

    return colors;
  },
  getChromaScale(palette: Palette, from: number, to: number) {
    const { stops } = palette;
    const cacheId = Palette._makeCacheId(stops, from, to);
    if (
      !palette._cache ||
      palette._cache.id !== cacheId ||
      palette._cache.scale === undefined ||
      typeof palette._cache.scale !== 'function'
    ) {
      palette._cache = {
        id: cacheId,
        scale: chroma
          .scale(stops.map((it) => it.color))
          .domain(stops.map((it) => from + it.offset * (to - from))),
      };
    }

    return palette._cache.scale;
  },
  equals(a: Palette, b: Palette) {
    return (
      a.name === b.name &&
      deepEqual(
        a.stops.map((it) => ({ color: it.color, offset: it.offset })),
        b.stops.map((it) => ({ color: it.color, offset: it.offset })),
      ) &&
      a.isDiscrete === b.isDiscrete &&
      a.id === b.id
    );
  },
  _makeCacheId(stops: ColorStop[], from: number, to: number) {
    return `${stops.map((it) => it.color + it.offset).join('-')}-${from}-${to}`;
  },
};

export const countSteps = (scale: PaletteScale) => {
  return Math.ceil((scale.to - scale.from) / scale.step);
};

const isLastStep = (stepsNumber: number, step: number, value: number) => {
  return value >= (stepsNumber - 1) * step;
};

const getCustomDiscreteColor = (
  scale: PaletteScale,
  value: number,
  stepsNumber: number,
  customColors: CustomDiscreteColor[],
) => {
  const scaleIndex = value === scale.to ? stepsNumber - 1 : value / scale.step;

  return customColors?.find((it) => it.index === scaleIndex)?.color;
};
