import { useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { Input } from 'tombac';
import clamp from 'lodash/clamp';
import { LayoutPropStylingProps } from 'tombac/dist/shared/propStyling/categories';

const parseNumber = (s: string): number | undefined => {
  const number = Number(s);
  if (s === '' || isNaN(number)) return;
  return number;
};

interface NumberInputProps
  extends Omit<React.ComponentProps<typeof Input>, 'onChange'> {
  value: number;
  min: number;
  max: number;
  isInvalid: (n: number) => boolean;
  onChange: (n: number) => void;
}

export const NumberInput: React.FC<
  LayoutPropStylingProps & NumberInputProps
> = ({ value, min, max, isInvalid, onChange, ...layoutPropStyling }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [invalid, setInvalid] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const number = parseNumber(e.target.value);
    if (number !== undefined) {
      onChange(clamp(number, min, max));
    }
    setInvalid(number === undefined || isInvalid(number));
  };

  useEffect(() => {
    if (!inputRef.current) return;
    inputRef.current.value = String(value);
    setInvalid(false);
  }, [value]);

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!invalid) e.target.value = String(value);
  };

  return (
    <Input
      {...layoutPropStyling}
      ref={inputRef}
      onChange={handleChange}
      onBlur={onBlur}
      invalid={invalid}
    />
  );
};
