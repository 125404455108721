import * as React from 'react';
import HelpCircle from 'react-feather/dist/icons/help-circle';
import { Box } from 'tombac';
import { IconTooltip } from 'components/UI/UI';
import { MapMenuToggle } from 'components/UI/MapMenu/MapMenuToggle';

interface ControlProps {
  selectedTo: number;
  selectedFrom: number;
  setLabels: (b: boolean) => void;
  labels: boolean;
  flowsPerKm: boolean;
  setFlowsPerKm: (b: boolean) => void;
}

export const MapFlowsControls: React.FC<ControlProps> = ({
  selectedTo,
  selectedFrom,
  setLabels,
  labels,
  flowsPerKm,
  setFlowsPerKm,
}) => {
  const isVia = selectedFrom > -1 && selectedTo > -1;

  return (
    <>
      <Box>
        <MapMenuToggle
          label="Labels"
          checked={labels}
          onChange={() => setLabels(!labels)}
        />
      </Box>
      <Box>
        {!isVia && (
          <MapMenuToggle
            label={
              (
                <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                  Flows/km²{' '}
                  <IconTooltip
                    icon={<HelpCircle size={18} />}
                    tooltip="Displays flows relative to the region area"
                  />
                </span>
              ) as any
            }
            checked={flowsPerKm}
            onChange={() => setFlowsPerKm(!flowsPerKm)}
          />
        )}
      </Box>
    </>
  );
};
